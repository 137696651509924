import React, { memo } from "react";
import { Divider, Space } from "antd";
import { get } from "lodash";
import { CategoryLabels } from "constants/dashboard.constants";
import AlectifyText from "static/texts.json";
import {
  CALENDAR_STATUS_COMPONENTS,
  EVENT_STATUS_CHIPS,
} from "./CalendarEvent";
import "./Calendar.scss";
import { IEventPopoverContentProps } from "./Calendar.interface";
import { displayDateTime, truncateText } from "utils/helpers";

const EventPopoverContent: React.FC<IEventPopoverContentProps> = memo(
  ({ event }) => {
    const {
      title,
      work_id,
      taskCategory,
      asset,
      asset_package,
      status,
      event_status,
      master_project,
      completionAt,
      completedAt,
      end,
    } = event;

    const assetName =
      (Array.isArray(asset) && asset.length > 1) ||
      (Array.isArray(asset_package) && asset_package.length > 1) ||
      (Array.isArray(asset) &&
        asset.length === 1 &&
        Array.isArray(asset_package) &&
        asset_package.length === 1)
        ? `Multiple Asset [${asset.length + asset_package.length}]`
        : Array.isArray(asset) && asset.length === 1
        ? get(asset, "[0].asset.name")
        : Array.isArray(asset_package) && asset_package.length === 1
        ? get(asset_package, "[0].area.name")
        : AlectifyText.NO_ASSET;

    /* const additionalAssets = (() => {
      const additionalCount =
        Math.max(0, asset.length - 1) + Math.max(0, asset_package.length - 1);
      return additionalCount > 0 ? `${additionalCount}` : "";
    })(); */

    /*  const assetLabel =
      (Array.isArray(asset) && asset.length > 1) ||
      (Array.isArray(asset_package) && asset_package.length > 1)
        ? `${additionalAssets}`
        : get(asset, "[0].asset.name")
        ? AlectifyText.SUB_ASSET_NAME
        : get(asset_package, "[0].area.name")
        ? AlectifyText.PARENT_ASSET_NAME
        : null; */

    const taskCategoryLabel = taskCategory
      ? CategoryLabels[taskCategory as keyof typeof CategoryLabels] || "Task"
      : "Task";

    return (
      <div className="popover-container-calendar">
        <Space direction="vertical">
          <div className="heading-sub-container">
            <span className="heading">
              {truncateText(title, 130) || "(No title)"}
            </span>
            <Divider className="mb-5 mt-5" />
            <div className="sub-heading">
              Work Id: <strong>{work_id}</strong>
            </div>
            <div className="sub-heading">
              Type: <strong>{taskCategoryLabel}</strong>
            </div>
            <div className="sub-heading">
              Asset: <strong>{`${assetName} `}</strong>
              {/* <strong>{assetLabel && [assetLabel]}</strong> */}
            </div>
            <div className="sub-heading">
              Site: <strong>{`${get(master_project, "[0].name")} `}</strong>
            </div>
            <div className="sub-heading red-text mb-10">
              Due Date: <strong>{`${displayDateTime(end, false)} `}</strong>
              {/* <strong>{assetLabel && [assetLabel]}</strong> */}
            </div>
            {completionAt && (
              <div className="sub-heading highlighted-date">
                Completion Date:{" "}
                <strong className="date-strong">
                  {displayDateTime(completionAt, false)}
                </strong>
              </div>
            )}
            {completedAt && (
              <div className="sub-heading highlighted-date">
                Closed at:{" "}
                <strong className="date-strong">
                  {displayDateTime(completedAt, false)}
                </strong>
              </div>
            )}
          </div>

          <Divider className="mb-5 mt-5" />

          <div className="chips-main">
            {
              CALENDAR_STATUS_COMPONENTS[
                status as keyof typeof CALENDAR_STATUS_COMPONENTS
              ]
            }
            {
              EVENT_STATUS_CHIPS(event)[
                event_status as keyof typeof EVENT_STATUS_CHIPS
              ]
            }
          </div>
        </Space>
      </div>
    );
  },
);

export default EventPopoverContent;
