import React from "react";
import { Space, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeleteTwoTone } from "@ant-design/icons";
import { ROUTES } from "routes/Routes.constants";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import { EditIconFilledIcon } from "components/icons";
import { IMasterProject } from "redux/components/master-project";
import { IProjectSparePart } from "redux/components/spare-parts";
import { setActiveMasterProject } from "redux/components/common/sources";
import AlectifyText from "static/texts.json";
import TextToLink from "components/shared/text-to-link";

interface Props {
  onDeleteSpareParts: (ids: string[]) => void;
  openSparePartForm: (record: IProjectSparePart) => void;
}

const useRecentlyAddedSparePartsColumns = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onMasterProjectClick = (masterProject: IMasterProject) => {
    dispatch(setActiveMasterProject(masterProject));
    navigate(`${ROUTES.MASTER_PROJECT_DETAILS}/${masterProject.id}`);
  };

  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.SITE,
      dataIndex: "project",
      visible: true,
      //   filters: Object.values(TaskPriorityEnum).map((value) => ({
      //     text: capitalizeFirstLetter(value),
      //     value,
      //   })),
      ellipsis: true,
      width: 150,
      render: (masterProject: IMasterProject) => (
        <Tooltip placement={"topLeft"} title={masterProject.name}>
          <TextToLink
            className="text-to-link-options"
            text={masterProject.name}
            onClick={() => onMasterProjectClick(masterProject)}
          />{" "}
        </Tooltip>
      ),
    },
    {
      width: 100,
      dataIndex: "category",
      visible: true,
      ellipsis: true,
      title: AlectifyText.CATEGORY,
      render: (category: string) => category || "-",
    },
    {
      width: 100,
      dataIndex: "partNumber",
      visible: true,
      ellipsis: true,
      title: AlectifyText.PART_NUMBER,
    },
    {
      width: 100,
      dataIndex: "description",
      visible: true,
      ellipsis: true,
      title: AlectifyText.DESCRIPTION,
      render: (value: string) => value || "-",
    },
    {
      width: 100,
      dataIndex: "preferredSupplierName",
      visible: true,
      ellipsis: true,
      title: AlectifyText.VENDOR,
    },
    {
      width: 100,
      dataIndex: "firmwareVersion",
      visible: true,
      ellipsis: true,
      title: AlectifyText.FIRMWARE_VERSION,
    },
    {
      width: 100,
      dataIndex: "system",
      visible: true,
      ellipsis: true,
      title: AlectifyText.SYSTEM,
      render: (value: string) => value || "-",
    },
    {
      width: 100,
      dataIndex: "room",
      visible: true,
      ellipsis: true,
      title: AlectifyText.ROOM,
      render: (value: string) => value || "-",
    },
    {
      width: 100,
      dataIndex: "rack",
      visible: true,
      ellipsis: true,
      title: AlectifyText.RACK,
      render: (value: string) => value || "-",
    },
    {
      width: 100,
      dataIndex: "shelf",
      visible: true,
      ellipsis: true,
      title: AlectifyText.SHELF,
      render: (value: string) => value || "-",
    },
    {
      width: 100,
      dataIndex: "remainingQuantity",
      visible: true,
      ellipsis: true,
      title: AlectifyText.IN_HAND_QTY,
    },
    {
      width: 100,
      dataIndex: "minimumQuantity",
      visible: true,
      ellipsis: true,
      title: AlectifyText.MIN_QTY,
    },
    {
      width: 100,
      dataIndex: "price",
      visible: true,
      ellipsis: true,
      title: AlectifyText.AVERAGE_UNIT_PRICE,
      render: (value: string) => value || "-",
    },
    {
      width: 100,
      dataIndex: "comments",
      visible: true,
      ellipsis: true,
      title: AlectifyText.ADDITIONAL_NOTE,
    },
    {
      width: 100,
      title: AlectifyText.ACTIONS,
      visible: true,
      align: "center",
      dataIndex: "",
      fixed: "right",
      render: (_, record: IProjectSparePart) => {
        return (
          <Space>
            <Tooltip title="Edit">
              <EditIconFilledIcon
                className="cursor-pointer"
                onClick={() => props.openSparePartForm(record)}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteTwoTone
                twoToneColor="#f56666"
                className="icon-font-size-18"
                onClick={() => props.onDeleteSpareParts([record.id])}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];
  return React.useMemo(() => columns, []);
};

export default useRecentlyAddedSparePartsColumns;
