import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { ColumnProps } from "antd/es/table";
import { ColumnGroupType } from "antd/lib/table";
import AlectifyText from "static/texts.json";
import { IAlectifyUploadTableListColumnProps } from "../AlectifyUpload.interface";

//TODO: it will be general component since its only being used in PM so defining type otherwise types will be general.
/* type PmDirectoryKey =
  | "IMAGES"
  | "VIDEOS"
  | "PM_REPORTS"
  | "PM_INSTRUCTIONS"
  | "COMMERCIAL_DOCS"; */

// type taskDirectoryKey = "PO" | "QUOTATION" | "SERVICE_REQUIRED" | "OTHERS";
const useFileListColumns = (props: IAlectifyUploadTableListColumnProps) => {
  /*   const folderOptions =
    props?.documentType === "TASK"
      ? Object.keys(TASK_DIRECTORIES).map((key: string) => {
          return {
            label: TASK_DIRECTORIES[key as taskDirectoryKey],
            value: TASK_DIRECTORIES[key as taskDirectoryKey],
          };
        })
      : Object.keys(PM_DIRECTORIES).map((key: string) => {
          return {
            label: PM_DIRECTORIES[key as PmDirectoryKey],
            value: key,
          };
        }); */

  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      width: 150,
      dataIndex: "name",
      title: AlectifyText.FILE_NAME,
      render: (value: string) => value,
    },
    // {
    //   width: 150,
    //   dataIndex: "folderName",
    //   title: AlectifyText.FOLDER + " *",
    //   render: (value: string, record: UploadFile) => (
    //     <AlectifySelect
    //       noFormItem
    //       name="folder"
    //       onChange={(e) => props.onDirectoryChange(record.uid, e)}
    //       placeholder="Select Folder *"
    //       options={folderOptions}
    //       style={{ width: "100%" }}
    //     />
    //   ),
    // },
    {
      width: 50,
      dataIndex: "",
      title: AlectifyText.ACTIONS,
      render: (_, record: any) => (
        <DeleteOutlined onClick={() => props.onFileDelete(record.uid)} />
      ),
    },
  ];
  return React.useMemo(() => columns, []);
};

export default useFileListColumns;
