import { IReduxAction } from "redux/interfaces";

export enum ITableFilterTypeEnum {
  OPEN = "OPEN",
  PAST = "PAST",
}
export interface ITableFilterInitialState {
  tableFilters: {
    filterType: ITableFilterTypeEnum;
    createdAt: string | null;
    dueDate: string | null | Object;
    statusShifterButtons: string[] | string | null;
    status: string[] | string | null;
    taskCategory: string | null;
    orderTypeTab: string | null;
  };
}

export interface ITableFilterActions {
  resetTableFilterState: IReduxAction<any>;
  setTableFilters: IReduxAction<any>;
}
