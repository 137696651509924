import React from "react";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import { MODAL_NAMES } from "constants/modal.constants";
import "./DataRoundsReports.scss";
import DataRoundsInfoModal from "./DataRoundsInfoModal";
import dayjs from "dayjs";
import { IDataRoundsEventsProps } from "./DataRoundsReports.interface";

const DataRoundsEvents: React.FC<IDataRoundsEventsProps> = ({
  event,
  projectId,
}) => {
  return (
    <div
      className="data-rounds-custom-event"
      onClick={() => {
        ModalServiceInstance.open(AlectifyModal, {
          title: `${dayjs(event.shift_date).format("dddd, DD YYYY")}`,
          name: MODAL_NAMES.ROUNDS_CALENDAR_DETAIL_MODAL,
          footer: null,
          width: 1400,
          className: "alectify-pdf-viewer-container",
          onCancel: () => {
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.ROUNDS_CALENDAR_DETAIL_MODAL,
            });
          },
          children: <DataRoundsInfoModal event={event} projectId={projectId} />,
        });
      }}
    >
      <span>
        <span className="border-container golden-lock " />
        <span>{`Issues (${event.issues_count})`}</span>
      </span>
      <br />
      <span>
        <span className="border-container illicit-pink " />
        <span>{`Docs (${event.docs_count})`}</span>
      </span>
      <br />
      <span>
        <span className="border-container sparrows-fire " />
        <span>{`Comments (${event.comments_count})`}</span>
      </span>
    </div>
  );
};

export default DataRoundsEvents;
