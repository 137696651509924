import React, { memo } from "react";
import { get, truncate } from "lodash";
import { Col, Row, Tag, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ISubProject } from "redux/components/sub-project";
import { IRootState } from "redux/rootReducer";
import AlectifyText from "static/texts.json";
import TasksColoredIcon from "components/icons/TasksColoredIcon";
import AssetsColoredIcon from "components/icons/AssetsColoredIcon";
import AssetPackagesColoredIcon from "components/icons/AssetPackagesColoredIcon";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { useForm } from "antd/es/form/Form";
import { CreateEditSubProject } from "components/shared/create-edit-sub-project";
import EditIcon from "components/icons/EditIcon";
import "./SubProjectCard.scss";
import { CHIP_COLORS } from "components/shared/phone-number/constants";
import { isFeatureEnabled } from "utils/helpers";
import { FEATURE_FLAGS } from "redux/components/Auth";
import { setActiveSubProject } from "redux/components/common/sources";
import PmExternalIcon from "components/icons/PmExternalIcon";
import { CloseOutlined } from "@ant-design/icons";
import TextToLink from "components/shared/text-to-link";

interface IAlectifyDetailCardTitleProps {
  subProject: ISubProject;
  onClick?: () => void;
  showEditButton?: boolean;
}

const SubProjectCard: React.FC<IAlectifyDetailCardTitleProps> = (props) => {
  const [FormInstance] = useForm();
  const dispatch = useDispatch();
  const activeMasterProject = useSelector(
    ({ common }: IRootState) => common.activeMasterProject,
  );

  return (
    <div className="sub-project-card">
      <div className="header" onClick={props?.onClick}>
        <Row justify="space-between" gutter={16}>
          <Col span={20} className="cursor-pointer">
            <TextToLink text={get(props?.subProject, "name", "-")} />
          </Col>
          <Col span={4}>
            <div className="draft-edit-icons">
              {isFeatureEnabled(FEATURE_FLAGS.MANAGE_PROJECT) &&
                props.showEditButton && (
                  <div
                    className="cursor-pointer"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      DrawerServiceInstance.open(AlectifyDrawer, {
                        name: DRAWER_CONSTANTS.CREATE_SUB_PROJECT_DRAWER,
                        destroyOnClose: true,
                        width: "auto",
                        title: `${truncate(
                          `${AlectifyText.UPDATE_ASSET_CATEGORY}: ${get(
                            props?.subProject,
                            "name",
                            "-",
                          )}`,
                          { length: 50, omission: "..." },
                        )}`,
                        showFooter: false,
                        onClose: () => {
                          FormInstance.resetFields();
                          DrawerServiceInstance.close(AlectifyDrawer, {
                            name: DRAWER_CONSTANTS.CREATE_SUB_PROJECT_DRAWER,
                          });
                        },
                        onSubmit: FormInstance.submit,
                        closeIcon: <CloseOutlined />,
                        closable: true,
                        children: (
                          <CreateEditSubProject
                            isEdit
                            FormInstance={FormInstance}
                            masterProjectId={activeMasterProject?.id}
                            subProjectEditId={props?.subProject?.id}
                          />
                        ),
                      });
                      dispatch(setActiveSubProject(props?.subProject));
                    }}
                  >
                    <EditIcon />
                  </div>
                )}
              {props?.subProject.is_draft && (
                <div className="draft-container ml-5">Draft</div>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div
        className="body cursor-pointer alectify-sub-project-card-description"
        onClick={props?.onClick}
      >
        <Typography.Paragraph>
          {get(props?.subProject, "description", "") || "-"}
        </Typography.Paragraph>
        {/* <AvatarGroup users={props?.subProject.accounts || 0} /> */}
      </div>

      <div className="footer">
        {/* Asset Package Tag */}
        <Tooltip placement="top" title={AlectifyText.PARENT_ASSET_NAME}>
          <Tag color={"#0d76a3"}>
            <AssetPackagesColoredIcon />
            <span>{props?.subProject.pkg_count || 0}</span>
          </Tag>
        </Tooltip>
        {/* Assets Tag */}
        <Tooltip placement="top" title={AlectifyText.ASSETS}>
          <Tag color="#DFF2FB" style={{ color: "#0d76a3" }}>
            <AssetsColoredIcon fill={"#0d76a3"} />
            <span>{props?.subProject.tags_count || 0}</span>
          </Tag>
        </Tooltip>
        {/* Task Tag */}
        <Tooltip placement="top" title={AlectifyText.TASKS}>
          <Tag color={CHIP_COLORS.TASK} style={{ color: "#fff" }}>
            <TasksColoredIcon />
            <span>{props?.subProject?.task_open_count || 0}</span>
          </Tag>
        </Tooltip>
        {/* PM External Tag */}
        <Tooltip placement="top" title={AlectifyText.PM_EXTERNAL}>
          <Tag color={CHIP_COLORS.PM_EXTERNAL} style={{ color: "#fff" }}>
            <PmExternalIcon fill="#fff" />
            <span>{props?.subProject?.pm_external_open_count || 0}</span>
          </Tag>
        </Tooltip>
      </div>
    </div>
  );
};

SubProjectCard.defaultProps = {
  subProject: {} as any,
  onClick: () => {},
  showEditButton: false,
};

export default memo(SubProjectCard);
