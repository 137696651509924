import { useEffect, useState } from "react";
import { Collapse, Space, Spin } from "antd";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyButton from "components/shared/button";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import CreateCompanyModal from "./CreateCompanyModal";
import CreateBranchModal from "./CreateBranchModal";
import CreateBranchAdminModal from "./CreateBranchAdminModal";
import AlectifyText from "static/texts.json";
import ListProjectTable from "./ListProjectTable";
import FeaturesTable from "./FeaturesTable";
import LicenseAndPermissionsTable from "./LicenseAndPermissionsTable";
import {
  fetchAdminProjects,
  fetchCompanies,
  fetchFeatures,
  fetchUsersWithBranches,
  softDeleteProject,
  updateFeatures,
} from "services/admin/admin.service";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";
import { IAllUsers, IListProjectState } from "./Admin.interface";

const Admin: React.FC = () => {
  const { Panel } = Collapse;

  const [state, setState] = useState({
    projects: {
      data: [],
      fetching: false,
      meta: null,
    },
    companies: [],
    features: {
      data: [],
      fetching: false,
      meta: null,
    },
    allUsers: {
      data: [],
      fetching: false,
      meta: null,
    },
    isSuperAdmin: {
      status: false,
      fetching: false,
    },
  });

  // const [loading, setLoading] = useState(false);

  const getCompanies = async () => {
    try {
      const response = await fetchCompanies();
      setState((prevData) => ({
        ...prevData,
        companies: response.data,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  /*  const onAuthorizeSubmit = async (values: ILoginPayload) => {
    try {
      setState((prevData) => ({
        ...prevData,
        isSuperAdmin: {
          ...prevData.isSuperAdmin,
          fetching: true,
        },
      }));
      const response = await loginRequest(values);
      if (response.data.is_superuser) {
        setState((prevData) => ({
          ...prevData,
          isSuperAdmin: {
            status: true,
            fetching: false,
          },
        }));
        closeAuthorizeModal();
        message.success(MESSAGES.ADMIN_MESSAGES.AUTHORIZED);
      } else {
        message.error(MESSAGES.ADMIN_MESSAGES.NOT_AUTHORIZED);
        setState((prevData) => ({
          ...prevData,
          isSuperAdmin: {
            status: false,
            fetching: false,
          },
        }));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }; */

  const getAdminProjects = async (options?: IPagination) => {
    try {
      setState((prevData) => ({
        ...prevData,
        projects: {
          ...prevData.projects,
          fetching: true,
        },
      }));

      const params = {
        type: "active",
        page: options?.page || PAGINATION.DEFAULT_START_PAGE,
        per_page: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
        ...options,
      };
      const response = await fetchAdminProjects(params);

      setState((prevData) => ({
        ...prevData,
        projects: {
          data: response.data,
          fetching: false,
          meta: response?.meta,
        },
      }));
    } catch (error) {
      console.log(error);
      setState((prevData) => ({
        ...prevData,
        projects: {
          ...prevData.projects,
          fetching: false,
        },
      }));
    }
  };

  const onSoftDelete = async (record: IListProjectState) => {
    try {
      await softDeleteProject(record?.id);
    } catch (error) {
      console.log(error);
    } finally {
      getAdminProjects();
    }
  };

  const getFeatures = async (options?: IPagination) => {
    try {
      setState((prevData) => ({
        ...prevData,
        features: {
          ...prevData.features,
          fetching: true,
        },
      }));
      const params = {
        page: options?.page || PAGINATION.DEFAULT_START_PAGE,
        per_page: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
        ...options,
      };
      const response = await fetchFeatures(params);
      setState((prevData) => ({
        ...prevData,
        features: {
          data: response.data,
          fetching: false,
          meta: response?.meta,
        },
      }));
    } catch (error) {
      setState((prevData) => ({
        ...prevData,
        features: {
          ...prevData.features,
          fetching: false,
        },
      }));
      console.log(error);
    }
  };

  const getUsers = async (options?: IPagination) => {
    try {
      setState((prevData) => ({
        ...prevData,
        allUsers: {
          ...prevData.allUsers,
          fetching: true,
        },
      }));
      const params = {
        page: options?.page || PAGINATION.DEFAULT_START_PAGE,
        per_page: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
        ...options,
      };
      const response = await fetchUsersWithBranches(params);
      setState((prevData) => ({
        ...prevData,
        allUsers: {
          data: response?.data,
          fetching: false,
          meta: response?.meta,
        },
      }));
    } catch (err) {
      setState((prevData) => ({
        ...prevData,
        allUsers: {
          ...prevData.allUsers,
          fetching: false,
        },
      }));

      console.log(err);
    }
  };

  const onFeatureChange = async (key: any, value: any, featureId: any) => {
    try {
      setState((prevData) => ({
        ...prevData,
        features: {
          ...prevData.features,
          fetching: true,
        },
      }));
      await updateFeatures(featureId, { [key]: value });
      getFeatures();
      setState((prevData) => ({
        ...prevData,
        features: {
          ...prevData.features,
          fetching: false,
        },
      }));
    } catch (error) {
      setState((prevData) => ({
        ...prevData,
        features: {
          ...prevData.features,
          fetching: false,
        },
      }));
      console.log(error);
    }
  };

  /*   const closeAuthorizeModal = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.AUTH_MODAL_ADMIN,
    });
  }; */

  const closeCreateCompanyModal = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.CREATE_COMPANY,
    });
  };

  const closeCreateBranchModal = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.CREATE_BRANCH,
    });
  };

  const closeCreateBranchAdminModal = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.CREATE_BRANCH_ADMIN,
    });
  };

  /*   const openAuthorizeModal = () => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.AUTH_MODAL_ADMIN,
      width: 800,
      footer: null,
      children: (
        <AuthorizeModal
          onAuthorizeSubmit={onAuthorizeSubmit}
          isSuperAdmin={state.isSuperAdmin}
        />
      ),
    });
  }; */
  const openCreateCompanyModal = () => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.CREATE_COMPANY,
      width: 800,
      footer: null,
      onCancel: closeCreateCompanyModal,
      children: <CreateCompanyModal getCompanies={getCompanies} />,
    });
  };

  const openCreateBranchModal = (record?: IAllUsers) => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.CREATE_BRANCH,
      width: 800,
      footer: null,
      onCancel: closeCreateBranchModal,
      children: (
        <CreateBranchModal
          companies={state?.companies}
          editRecord={record}
          getUsers={getUsers}
        />
      ),
    });
  };

  const openCreateBranchAdminModal = () => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.CREATE_BRANCH_ADMIN,
      width: 800,
      footer: null,
      onCancel: closeCreateBranchAdminModal,
      children: (
        <CreateBranchAdminModal
          companies={state?.companies}
          getUsers={getUsers}
        />
      ),
    });
  };

  useEffect(() => {
    // if (state.isSuperAdmin.status) {
    Promise.all([
      getCompanies(),
      getFeatures(),
      getAdminProjects(),
      getUsers(),
    ]);
    // }
  }, [state.isSuperAdmin.status]);

  return (
    <Spin spinning={false}>
      <div className="d-flex justify-flex-end mt-20 mb-20">
        <Space>
          <AlectifyButton
            text="Create Company"
            type="primary"
            onClick={openCreateCompanyModal}
          />
          <AlectifyButton
            text="Create Branch"
            type="primary"
            onClick={() => openCreateBranchModal()}
          />
          <AlectifyButton
            text="Create Branch Admin"
            type="primary"
            onClick={openCreateBranchAdminModal}
          />
        </Space>
      </div>
      <div className="p-10">
        <Collapse className="mb-10">
          <Panel header={AlectifyText.LIST_OF_PROJECTS} key="1">
            <ListProjectTable
              projects={state?.projects}
              getAdminProjects={getAdminProjects}
              onSoftDelete={onSoftDelete}
            />
          </Panel>
        </Collapse>

        <Collapse className="mb-10">
          <Panel header={AlectifyText.LICENSE_WISE_FEATURE_CONTROL} key="1">
            <FeaturesTable
              features={state?.features}
              onFeatureChange={onFeatureChange}
              getFeatures={getFeatures}
            />
          </Panel>
        </Collapse>

        <Collapse className="mb-10">
          <Panel header={AlectifyText.USER_LICENSE_AND_PERMISSION} key="1">
            <LicenseAndPermissionsTable
              allUsers={state?.allUsers}
              getUsers={getUsers}
              openCreateBranchModal={openCreateBranchModal}
            />
          </Panel>
        </Collapse>
      </div>
    </Spin>
  );
};

export default Admin;
