import { Space, Tag } from "antd";
import { CHIP_COLORS } from "components/shared/phone-number/constants";
import AlectifyText from "static/texts.json";
import PmExternalIcon from "components/icons/PmExternalIcon";

const PmExternalTag: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Tag
      color={CHIP_COLORS.PM_EXTERNAL}
      style={{ color: "#fff" }}
      className="round-tag"
    >
      <Space>
        <PmExternalIcon fill="#fff" />
        <span>{text || AlectifyText.PM_EXTERNAL}</span>
      </Space>
    </Tag>
  );
};

export default PmExternalTag;
