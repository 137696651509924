import { Dispatch } from "react";
import { IParams, SourceActionType } from "redux/interfaces";
import { actions } from "..";
import { fetchPMExternal } from "services/pm-external/pm-external.service";
import { getPmsCounts } from "redux/components/pm-internal/sources";

export const getPmExternal: SourceActionType = (
  masterProjectId: string,
  subProjectId: string,
  params: IParams,
  isMasterPM: boolean = false,
  pmType: string,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.setPMExterLoader(true));
      const response = await fetchPMExternal(
        masterProjectId,
        subProjectId,
        params,
        isMasterPM,
        pmType,
      );
      if (response?.status) {
        dispatch(actions.getPmExternal(response));
        dispatch(actions.setPMExterLoader(false));
        dispatch(getPmsCounts(masterProjectId, subProjectId, params));
      }
    } catch (error) {
      dispatch(actions.setPMExterLoader(false));
      console.log(error);
    } finally {
      dispatch(actions.setPMExterLoader(false));
    }
  };
};

export const setWorkOrderCardSwitcher: SourceActionType = (
  payload: boolean,
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setWorkOrderCardSwitcher(payload));
  };
};
