export const GET_PROJECT_ASSETS = "/projects/{subProjectId}/assets/";
export const CREATE_SINGLE_ASSET = "/projects/{subProjectId}/tags/";
export const GET_PROJECT_ASSET_DETAIL =
  "/projects/{subProjectId}/tags/{assetId}/";
export const DOWNLOAD_ASSETS_FILE = "/projects/{subProjectId}/master-tags-csv/";
export const GET_PROJECT_ASSETS_BY_SEARCH =
  "/projects/{subProjectId}/tags/search/";
export const GET_ASSET_PACKAGE_TEAM_MEMNERS =
  "projects/{subProjectId}/packages/{assetPackageId}/team-members/";
export const CLEAR_DATA = `/projects/{subProjectId}/clear-data/`;
export const RESET_TAGS = `/projects/{subProjectId}/assets/reset-order/`;
export const ADD_ASSET_MEMBER = `/projects/{subProjectId}/tags/add-users/`;
export const REMOVE_ASSET_MEMBER = `/projects/{subProjectId}/tags/remove-users/`;
export const GET_TIMELINE = "/timelines/{timelineId}";
export const GET_GLOBAL_ASSET_PKG = "/global_tag_pkg_search_api/";
export const UPLOAD_ASSET_PROFILE =
  "/projects/{projectid}/tags/{tagid}/upload-profile-image/";
export const DELETE_ASSETS = "/projects/{subProjectId}/delete-assets/";
export const TAGS_RECOMMENDATION =
  "/projects/{sub_project_id}/tags/{tag_id}/summary/";
export const PACKAGES_RECOMMENDATION =
  "/projects/{sub_project_id}/packages/{package_id}/summary/";
