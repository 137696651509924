import { isEmpty } from "lodash";
import { memo, useEffect, useRef, useState } from "react";
import { IRootState } from "redux/rootReducer";
import { Col, Row, Space, Spin, Tooltip, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import AlectifyText from "static/texts.json";
import { ICommentProps } from "./Comments.interface";
import CommentList from "./CommentList";
import Editor from "../editor";
import AlectifyEmpty from "../empty/AlectifyEmpty";
import { ICommentState } from "../editor/editor.interface";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { createNewComment } from "services/comments/comments.service";
import { Typography } from "antd";
import { DocumentUploadedFromEnum, FOLDER_NAME } from "enums/folder-type.enum";
import { uploadPrevMaintenanceDocuments } from "services/pm-internal/pm-internal.service";
import { getComments } from "redux/components/common/sources";
import { actions, TimelineTypeEnum } from "redux/components/common";
import {
  getAttachmentsMedia,
  getPmInternalAttachments,
} from "redux/components/pm-internal/sources";
import TimelineCommentList from "./TimelineCommentList";
import { getUnreadNotificationsCount } from "redux/components/notifications/sources";
import { MESSAGES } from "constants/messages";
import "./Comment.scss";
import { LeftArrowIcon } from "components/icons";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";

const Comments = ({
  details,
  referenceId,
  subProjectId,
  reference_type,
  showTitleAndCount,
  isDetailPage,
  hideEditor,
  // noScrollToBottom,
  // systemGeneratedOnly,
  isTimeline,
  showHideButton,
  onChangeHideButton,
}: ICommentProps) => {
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.common);
  const [comments, setComments] = useState<ICommentState>({
    comments: [],
    submitting: false,
    value: "",
    fetching: false,
    attachment: null,
  });

  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
    signedUrls: true,
    ordering: "-created_at",
  });

  const user = useSelector((state: IRootState) => state.auth.user);

  const onChange = (e: string) => {
    setComments((prev) => {
      return { ...prev, value: e };
    });
  };

  const onAddAttachment = (attachment: UploadChangeParam<UploadFile<any>>) => {
    setComments((prev) => {
      return { ...prev, attachment };
    });
  };

  const onDeleteAttachment = (index: number) => {
    let fileList = comments.attachment.fileList.filter(
      (d: any, i: number) => i !== index,
    );
    setComments((prev) => {
      return {
        ...prev,
        ...comments,
        attachment: { ...comments.attachment, fileList },
      };
    });
  };

  // <<<===========ATTACHMENT UPLOAD =====>>>>
  const uploadPmDocument = async () => {
    try {
      const s3Data = [];
      const formData = new FormData();

      // Append common fields to FormData
      formData.append("userId", user?.id || "");
      formData.append("projectId", details?.project?.id || "");
      formData.append("subProjectId", details?.subProject?.id || "");
      formData.append("from", DocumentUploadedFromEnum.MESSAGING_CENTER);

      // Append each file to FormData with a unique identifier
      (comments?.attachment?.fileList || []).forEach(
        (document: any, index: number) => {
          formData.append(FOLDER_NAME.ACTIVITY, document.originFileObj);
        },
      );

      const response = await uploadPrevMaintenanceDocuments(
        details?.id,
        formData,
      );

      if (response?.data) {
        s3Data.push(
          ...response.data.map((item: any) => ({
            key: item.key,
            name: item.originalname,
          })),
        );
      }

      if (isDetailPage) {
        const params = {
          page: 1,
          limit: 5,
          signedUrls: true,
        };
        dispatch(getPmInternalAttachments(details?.id, params));

        dispatch(
          getAttachmentsMedia(details?.id, {
            page: 1,
            limit: 5,
            signedUrls: true,
            fileType: "media",
          }),
        );
      }

      return s3Data;
    } catch (error) {
      throw error;
    }
  };

  const onCommentSubmit = async () => {
    try {
      if (isEmpty(comments.value) && isEmpty(comments.attachment)) {
        notification.error({
          message: MESSAGES.COMMMENTS_ERROR.UPLOAD_FILE,
        });
        return;
      }
      /* if (!comments.value) {
        notification.error({
          message: MESSAGES.COMMMENTS_ERROR.ADD_COMMENTS,
        });
        return;
      } */

      setComments((prev) => {
        return { ...prev, ...comments, submitting: true };
      });
      let s3Keys: string[] = [];
      let fileNames = [];
      if (!isEmpty(comments.attachment)) {
        const s3Data = await uploadPmDocument();
        if (s3Data) {
          s3Keys = s3Data.map((file: any) => file.key);
          fileNames = s3Data.map((file: any) => file.name);
        }
      }

      const newComment = {
        sent_by: user.id,
        text: comments.value,
        s3_key: s3Keys,
        content_type: s3Keys.length
          ? AlectifyText.ATTACHMENT
          : AlectifyText.TEXT,
        file_name: fileNames,
        reference_type: reference_type,
      };
      const response = await createNewComment(
        subProjectId,
        referenceId,
        newComment,
      );
      dispatch(actions.pushNewComment(response.data));
      dispatch(getUnreadNotificationsCount());
      if (
        referenceId &&
        reference_type !== TimelineTypeEnum.SPARE_PART &&
        params
      ) {
        dispatch(
          getComments(
            subProjectId,
            referenceId,
            params,
            false,
            params.page !== 1,
          ),
        );
      }

      setComments((prev) => {
        return {
          ...prev,
          ...comments,
          submitting: false,
          value: "",
          attachment: null,
        };
      });
    } catch (error) {
      setComments((prev) => {
        return { ...prev, ...comments, submitting: false };
      });
      throw error;
    }
  };

  useEffect(() => {
    if (
      referenceId &&
      reference_type !== TimelineTypeEnum.SPARE_PART &&
      params
    ) {
      dispatch(
        getComments(
          subProjectId,
          referenceId,
          params,
          false,
          params?.page !== 1 ? true : false,
        ),
      );
    } else if (reference_type === TimelineTypeEnum.SPARE_PART) {
      addSparePartComment();
    }
  }, [referenceId, subProjectId, params.page]);

  const addSparePartComment = () => {
    const comment = {
      id: "1",
      image: "",
      s3_key: "",
      s3_url: "",
      file_name: "",
      fetching: false,
      content_type: "text",
      created_at: new Date(),
      is_system_generated: true,
      text: `has withdrawn ${details?.quantity} parts.`,
      sent_by: {
        id: details?.userId,
        email: details?.userEmail,
        last_name: details?.userLastName,
        first_name: details?.userFirstName,
      },
    };
    dispatch(
      actions.setComments({ data: [comment], fetching: false, meta: null }),
    );
  };

  const getParams = (page: number) => {
    setParams({ ...params, page });
  };
  const replyBodyRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (replyBodyRef.current) {
      replyBodyRef.current.scrollTop = replyBodyRef.current.scrollHeight;
    }
  }, [state.comments.data.length]); // Scroll to bottom when comments are updated

  return (
    <div className="comments-container">
      <div className="comments-header-title">
        {showTitleAndCount && (
          <Space>
            {showHideButton && (
              <div
                className="hide-btn-container"
                onClick={() => {
                  onChangeHideButton && onChangeHideButton(true);
                  DrawerServiceInstance.close(AlectifyDrawer, {
                    name: DRAWER_CONSTANTS.MESSAGING_CENTER,
                  });
                }}
              >
                <Tooltip title={AlectifyText.HIDE}>
                  <div className="inner-container">
                    <div className="arrow-main">
                      <span className="icon-rotate first-icon">
                        <LeftArrowIcon height="9" width="11" stroke="#fff" />
                      </span>
                      <span className="second-icon icon-rotate">
                        <LeftArrowIcon height=" 13" width="12" stroke="#fff" />
                      </span>
                    </div>
                  </div>
                </Tooltip>
              </div>
            )}
            <Typography.Title level={5} className="m-0 ">
              {`${AlectifyText.MESSAGING_CENTER} (${
                state?.comments?.meta?.total_count || 0
              })`}
            </Typography.Title>
          </Space>
        )}
      </div>
      <div
        ref={replyBodyRef}
        className={`reply-body ${
          state.comments?.data?.length > 0 ? "" : "center-div"
        } ${!isEmpty(comments.attachment?.fileList) ? "reduce-height" : ""}`}
      >
        <Spin spinning={state.comments?.fetching}>
          {state?.comments?.data?.length > 0 ? (
            <>
              {isTimeline ? (
                <TimelineCommentList getParams={getParams} params={params} />
              ) : (
                <CommentList getParams={getParams} params={params} />
              )}
            </>
          ) : (
            <AlectifyEmpty description={AlectifyText.NO_MESSAGES_FOUND} />
          )}
        </Spin>
      </div>
      {!hideEditor && (
        <div className="edit-box">
          <Row gutter={20} className="mt-10">
            {/* <Col span={2}>
              <Avatar src={user?.image} />
            </Col> */}
            <Col span={24}>
              <Editor
                attachment={comments.attachment}
                comments={state?.comments?.data}
                onChange={onChange}
                onSubmit={onCommentSubmit}
                submitting={comments.submitting}
                value={comments.value}
                onAddAttachment={onAddAttachment}
                onDeleteAttachment={onDeleteAttachment}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default memo(Comments);
