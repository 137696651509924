import { IParams } from "redux/interfaces";
import { PAGINATION } from "constants/index";
import { IPagination } from "../table/AlectifyTable.interface";
import { memo, useEffect, useState } from "react";
import { IPackageDetailAssetsProps } from "./PackageDetailAssets.interface";
import { assetsInitialState, IAssetsResponse } from "redux/components/assets";
import {
  uploadAssetPackageImage,
  fetchPackageDetailAssets,
} from "services/asset-packages/asset-packages.service";
import AlectifyTable from "../table";
import { ROUTES } from "routes/Routes.constants";
import AlectifyButton from "../button";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/components/notifications";
import { Col, Image, Row, Typography, Upload, message } from "antd";
import AlectifyText from "static/texts.json";
import { AlectifyDetailCard, AlectifyDetailCardText } from "../detail-card";
import {
  getAssetPackageDetail,
  getProjectAssetPackages,
} from "redux/components/asset-packages/sources";
import { IRootState } from "redux/rootReducer";
import { truncate } from "lodash";
import { displayDateTime } from "utils/helpers";
import { MESSAGES } from "constants/messages";
import { getAssetPackageDocuments } from "redux/components/documents/sources";
import { CameraOutlined } from "@ant-design/icons";
import { NO_IMAGE_AVATAR } from "../assets-table/AssetsTable.constants";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ViewImage from "../view-image/ViewImage";
import PackageAssetsColumns from "./effects/usePackageAssetsColumns";

const PackageDetailAssets: React.FC<IPackageDetailAssetsProps> = (
  props: IPackageDetailAssetsProps,
) => {
  const { subProjectId, assetPackageId, taskRecord } = props;
  const dispatch = useDispatch();
  const columns = PackageAssetsColumns(props);
  const [assets, setAssets] = useState<IAssetsResponse>(
    assetsInitialState.assets,
  );
  const { assetPackageDetail } = useSelector(
    ({ assetPackage }: IRootState) => assetPackage,
  );
  const { activeSubProject } = useSelector(({ common }: IRootState) => common);
  const getPackageAssets = async (options?: IPagination) => {
    const params = {
      ...options,
      page: options?.page || PAGINATION.DEFAULT_START_PAGE,
      per_page: options?.per_page || PAGINATION.PAGE_SIZE_20,
    };
    setAssets({ ...assets, fetching: true });
    const response = await fetchPackageDetailAssets(
      subProjectId,
      assetPackageId,
      params as IParams,
    );
    setAssets({ ...response, fetching: false });
  };

  useEffect(() => {
    getPackageAssets();
  }, []);
  useEffect(() => {
    if (subProjectId && assetPackageId) {
      dispatch(getAssetPackageDetail(subProjectId, assetPackageId));
    }
  }, [subProjectId, assetPackageId]);

  const getDocuments = (options?: IPagination) => {
    const projectId = subProjectId || activeSubProject?.id;

    const params = {
      page: options?.page || PAGINATION.DEFAULT_START_PAGE,
      per_page: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      searchText: options?.searchText || null,
      ...options,
    } as any;
    if (props.assetPackageId) params.package = props.assetPackageId;
    dispatch(getAssetPackageDocuments(projectId, params));
    dispatch(getProjectAssetPackages(projectId, params));
  };

  const handleImageUpload = async (file: any) => {
    const projectId = subProjectId || activeSubProject?.id || "";
    const tagId = assetPackageDetail?.data?.id || assetPackageId || "";
    message.loading(MESSAGES.FILE_UPLOAD_MESSAGES.IMAGE_UPLOADING);
    try {
      const response = await uploadAssetPackageImage(projectId, tagId, {
        image: file,
      });
      if (response) {
        dispatch(getAssetPackageDetail(subProjectId, assetPackageId));
        getDocuments();
        message.success(
          MESSAGES.FILE_UPLOAD_MESSAGES.ASSET_PACKAGE_IMAGE_UPLOADED,
        );
      }
    } catch (error: any) {
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
      console.error("Error uploading image:", error);
    }
  };

  const goToAssetPackageDetail = () => {
    const url = ROUTES.TIMELINE.replace(":subProjectId", subProjectId)
      .replace(":equipmentType", "asset-package")
      .replace(":equipmentId", assetPackageId);
    if (props.navigate) {
      dispatch(actions.toggleNotificationDropdown(false));
      props.navigate(url);
      DrawerServiceInstance.close(AlectifyDrawer, {
        name: [
          DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
          DRAWER_CONSTANTS.MAINTENANCE_PREDICTION_DRAWER,
        ],
      });
    }
  };

  const PreviewImage = () => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.VIEW_IMAGE_MODAL,
      title: AlectifyText.VIEW_IMAGE,
      children: (
        <ViewImage
          imageUrl={assetPackageDetail?.data?.image || NO_IMAGE_AVATAR}
        />
      ),
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.VIEW_IMAGE_MODAL,
        });
      },
      footer: null,
    });
  };

  return (
    <>
      <Row gutter={16}>
        {props.assetDetailColumn && (
          <Col span={10}>
            <AlectifyDetailCard loading={assetPackageDetail?.fetching}>
              <>
                <div className="profile-image-container">
                  <div className="image-wrapper">
                    <Image
                      preview={false}
                      src={assetPackageDetail?.data?.image || NO_IMAGE_AVATAR}
                      className="contact-profile-image"
                      onClick={PreviewImage}
                    />
                    {props?.isUploadbutton && (
                      <Upload
                        maxCount={1}
                        accept="image/*"
                        showUploadList={false}
                        beforeUpload={() => false}
                        onChange={(info) => {
                          const { fileList } = info;
                          handleImageUpload(fileList[0].originFileObj);
                        }}
                      >
                        <CameraOutlined className="camera-icon" />
                      </Upload>
                    )}
                  </div>
                </div>
                <Typography.Paragraph className="text-align-center mt-5">
                  {assetPackageDetail.data?.description}
                </Typography.Paragraph>
                <AlectifyDetailCardText
                  heading={AlectifyText.ITEM_DETAIL}
                  label={AlectifyText.ITEM_NUMBER}
                  value={
                    assetPackageDetail?.data?.serial_number ||
                    taskRecord?.area?.serialNumber
                  }
                />
                <AlectifyDetailCardText
                  label={AlectifyText.ASSET_CATEGORY}
                  value={
                    activeSubProject?.name ||
                    taskRecord?.subProject?.name ||
                    "-"
                  }
                />
                <AlectifyDetailCardText
                  label={AlectifyText.PARENT_ASSET_NAME}
                  value={truncate(
                    assetPackageDetail?.data?.name || taskRecord?.area?.name,
                    {
                      length: 20,
                      omission: "...",
                    },
                  )}
                />
                <AlectifyDetailCardText
                  heading={AlectifyText.MANUFACTUROR_INFORMATION}
                  label={AlectifyText.MANUFACTURER}
                  value={assetPackageDetail?.data?.manufacture?.name || "-"}
                />
                <AlectifyDetailCardText
                  label={AlectifyText.WARRANTY_DATE}
                  value={
                    displayDateTime(assetPackageDetail?.data?.warranty_date) ||
                    "-"
                  }
                />
                <AlectifyDetailCardText
                  label={AlectifyText.INSTALLATION_DATE}
                  value={
                    displayDateTime(
                      assetPackageDetail?.data?.installation_date,
                    ) || "-"
                  }
                />
                <AlectifyDetailCardText
                  label={AlectifyText.MODEL}
                  value={assetPackageDetail?.data?.model || "-"}
                />
                <AlectifyDetailCardText
                  label={AlectifyText.SERIAL_NUMBER}
                  value={assetPackageDetail?.data?.model_serial_number || "-"}
                />
                <AlectifyDetailCardText
                  heading={AlectifyText.OTHER_INFORMATION}
                  label={AlectifyText.ASSET_TYPE}
                  value={assetPackageDetail?.data?.asset_type || "-"}
                />
                <AlectifyDetailCardText
                  label={AlectifyText.ASSET_STATUS}
                  value={assetPackageDetail?.data?.status || "-"}
                />
                <AlectifyDetailCardText
                  label={AlectifyText.GROUP}
                  value={assetPackageDetail?.data?.group || "-"}
                />
                <AlectifyDetailCardText
                  label={AlectifyText.LOCATION}
                  value={assetPackageDetail?.data?.location || "-"}
                />
                {/* <AlectifyDetailCardText
                label={AlectifyText.PREFERRED_SUPPLIER}
                value={assetPackageDetail?.data?.preferred_supplier || "-"}
              /> */}
                {props?.navigate && props?.isDetailsbutton && (
                  <div className="alectify-sticky-buttons-container">
                    <AlectifyButton
                      type="primary"
                      text={AlectifyText.GO_TO_ASSET_PACKAGE_DETAIL}
                      onClick={goToAssetPackageDetail}
                      className="mb-15 mt-5 width-100"
                    />
                  </div>
                )}
              </>
            </AlectifyDetailCard>
          </Col>
        )}
        <Col span={props.assetDetailColumn ? 14 : 24}>
          <AlectifyTable
            showSearch
            customSearchKey="search"
            columns={columns}
            dataSource={assets.data}
            loading={assets.fetching}
            onDataFetch={getPackageAssets}
            total={assets.meta.total_count}
            defaultPageSize={PAGINATION.PAGE_SIZE_20}
            tableTitle={`Assets List (${assets.meta.total_count})`}
            scroll={{ y: "calc(100vh - 320px)" }}
          />
        </Col>
      </Row>
    </>
  );
};

PackageDetailAssets.defaultProps = {
  assetDetailColumn: true,
};

export default memo(PackageDetailAssets);
