import { MULTIPART_HEADERS, http, pmHttp } from "utils/client";
import { IParams } from "redux/interfaces";
import { AxiosResponse } from "axios";

import {
  ADD_ASSET_MEMBER,
  CLEAR_DATA,
  CREATE_SINGLE_ASSET,
  DELETE_ASSETS,
  DOWNLOAD_ASSETS_FILE,
  GET_ASSET_PACKAGE_TEAM_MEMNERS,
  GET_GLOBAL_ASSET_PKG,
  GET_PROJECT_ASSETS,
  GET_PROJECT_ASSETS_BY_SEARCH,
  GET_PROJECT_ASSET_DETAIL,
  GET_TIMELINE,
  PACKAGES_RECOMMENDATION,
  REMOVE_ASSET_MEMBER,
  RESET_TAGS,
  TAGS_RECOMMENDATION,
  UPLOAD_ASSET_PROFILE,
} from "./assets.endpoints";
import {
  IAsset,
  IAssetsResponse,
} from "redux/components/assets/assets.interface";
import { message } from "antd";
import { MESSAGES } from "constants/messages";
import { IUsersResponse } from "redux/components/users";
import { ITimelineResponse } from "redux/components/common";
import {
  IAISummaryResponse,
  IGlobalSearchResponse,
} from "components/shared/global-search/GlobalSearch.interface";
import { SERVICE_UNIQUE_ID } from "utils/constants";
import { IPayloadDeleteAsset } from "components/shared/manage-assets/AddAssetManaul.interface";

/**
 * Fetches assets for a given sub project ID.
 *
 * @param subProjectId - The ID of the sub project to fetch assets for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project assets data.
 * @throws If an error occurs during the request.
 */
export const fetchProjectAssets = async (
  subProjectId: string,
  params?: IParams, // Make params optional
): Promise<IAssetsResponse> => {
  try {
    const url = GET_PROJECT_ASSETS.replace("{subProjectId}", subProjectId);
    const response: AxiosResponse<IAssetsResponse> = await http.get(
      url,
      params,
    );
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

export const createProjectAssets = async (
  subProjectId: string,
  payload: FormData,
): Promise<IAssetsResponse> => {
  try {
    const url = GET_PROJECT_ASSETS.replace("{subProjectId}", subProjectId);
    const response: AxiosResponse<IAssetsResponse> = await http.post(
      url,
      payload,
      MULTIPART_HEADERS,
    );
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

export const createSingleAsset = async (
  subProjectId: string,
  payload: FormData,
): Promise<IAssetsResponse> => {
  try {
    const url = CREATE_SINGLE_ASSET.replace("{subProjectId}", subProjectId);
    const response: AxiosResponse<IAssetsResponse> = await http.post(
      url,
      payload,
    );
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

export const fetchAssetAndAssetPackages = async (
  subProjectId: string,
  params: IParams,
) => {
  try {
    const url = GET_PROJECT_ASSETS.replace("{subProjectId}", subProjectId);
    const response = await http.get(url, params);
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Fetches asset detail for a given asset ID.
 *
 * @param assetId - The ID of the asset to fetch asset detail for.
 * @param subProjectId - The ID of the sub project to fetch assets for.
 * @returns A Promise that resolves to the asset detail.
 * @throws If an error occurs during the request.
 */
export const fetchAssetDetail = async (
  assetId: string,
  subProjectId: string,
): Promise<IAsset> => {
  try {
    const url = GET_PROJECT_ASSET_DETAIL.replace(
      "{subProjectId}",
      subProjectId,
    ).replace("{assetId}", assetId);
    const response: AxiosResponse<IAsset> = await http.get(url);
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Download assets files for a given project ID.
 *
 * @param subProjectId - The ID of the sub project to fetch assets for.
 * @returns A Promise that resolves to the asset detail.
 * @throws If an error occurs during the request.
 */

export const getDownloadAssetsFile = async (subProjectId: string) => {
  try {
    const hideLoadingMessage = message.loading(
      MESSAGES.FILE_DOWNLOAD_MESSAGES.DOWNLOAD_INPROGRESS,
      0,
    ); // Duration 0 means the message stays until explicitly closed

    const url = DOWNLOAD_ASSETS_FILE.replace("{subProjectId}", subProjectId);
    const response: AxiosResponse<any> = await http.get(url, {
      responseType: "blob",
    });

    const blob = new Blob([response.data], { type: "text/csv;charset=utf-8;" });
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", "assets.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);

    hideLoadingMessage(); // Hide the loading message
    message.success(MESSAGES.FILE_DOWNLOAD_MESSAGES.DOWNLOAD_SUCCESS);
  } catch (ex: any) {
    message.error(MESSAGES.FILE_DOWNLOAD_MESSAGES.DOWNLOAD_ERROR);
  }
};

/* Fetches asset package team members by given asset package id.
 *
 * @param assetPackageId - The ID of the asset package to fetch asset package team member.s
 * @param subProjectId - The ID of the sub project to fetch asset packages team members.
 * @returns A Promise that resolves to the asset detail.
 * @throws If an error occurs during the request.
 */
export const fetchAssetPackageTeamMembers = async (
  assetPackageId: string,
  subProjectId: string,
): Promise<IUsersResponse> => {
  try {
    const url = GET_ASSET_PACKAGE_TEAM_MEMNERS.replace(
      "{subProjectId}",
      subProjectId,
    ).replace("{assetPackageId}", assetPackageId);
    const response: AxiosResponse<IUsersResponse> = await http.get(url);
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Fetches project assets by search keyword for a given sub project ID.
 *
 * @param subProjectId - The ID of the sub project to fetch project assets.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project assets data.
 * @throws If an error occurs during the request.
 */
export const fetchProjectAssetsBySearch = async (
  subProjectId: string,
  params?: IParams, // Make params optional
): Promise<IAssetsResponse> => {
  try {
    const url = GET_PROJECT_ASSETS_BY_SEARCH.replace(
      "{subProjectId}",
      subProjectId,
    );
    const response: AxiosResponse<IAssetsResponse> = await http.get(
      url,
      params,
    );
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Deletes tags associated with a specific project.
 *
 * @param {string} subProjectId - The ID of the project from which tags will be deleted.
 * @param {string} body - The request body containing information about the tags to be deleted.
 * @returns {Promise} - A Promise that resolves to the response data upon successful deletion.
 * @throws {Error} - Throws an error if the deletion process encounters any issues.
 */
export const deleteAsset = async (
  subProjectId: string,
  body: IPayloadDeleteAsset,
) => {
  try {
    const response = await http.delete(
      DELETE_ASSETS.replace("{subProjectId}", subProjectId),
      body,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Clears old tags associated with a specific project.
 *
 * @param {string} subProjectId - The ID of the project from which old tags will be cleared.
 * @returns {Promise} - A Promise that resolves to the response data upon successful clearance.
 * @throws {Error} - Throws an error if the clearance process encounters any issues.
 */
export const clearOldTags = async (subProjectId: string) => {
  try {
    const response = await http.delete(
      CLEAR_DATA.replace("{subProjectId}", subProjectId),
    );
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Creates tags for a specific project using the provided asset information.
 *
 * @param {string} subProjectId - The ID of the project for which tags will be created.
 * @param {IAsset} body - The asset information used to create tags for the project.
 * @returns {Promise} - A Promise that resolves to the response data upon successful tag creation.
 * @throws {Error} - Throws an error if the tag creation process encounters any issues.
 */
export const createTags = async (subProjectId: string, body: IAsset[]) => {
  try {
    const response = await http.post(
      GET_PROJECT_ASSETS.replace("{subProjectId}", subProjectId),
      body,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Fetches tags associated with a specific project.
 *
 * @param {string} subProjectId - The ID of the project for which tags will be fetched.
 * @param {IParams} [params] - Optional parameters for the API request, such as query parameters.
 * @returns {Promise} - A Promise that resolves to the response data containing the fetched tags.
 * @throws {Error} - Throws an error if the fetching process encounters any issues.
 */
export const fetchTagsByProject = async (
  subProjectId: string,
  params?: IParams, // Make params optional
) => {
  try {
    let url = GET_PROJECT_ASSETS.replace("{subProjectId}", subProjectId);
    const response = await http.get(url, params);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * This function, resetAssetsOrder, is designed to reset the order of tags for a specific subproject.
 * It takes two parameters: subProjectId (string) and payload (optional string).
 *
 * @param {string} subProjectId - Represents the identifier of the subproject.
 * @param {string} [payload] - An optional payload, which could be a string.
 * @returns {Promise<any>} A Promise that resolves to the data from the successful response.
 * @throws {Error} If an error occurs during the HTTP request.
 */

export const resetAssetsOrder = async (
  subProjectId: string,
  payload?: string,
) => {
  try {
    const response = await http.post(
      RESET_TAGS.replace("{subProjectId}", subProjectId),
      payload,
    );
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Updates the details of a specific asset within a subproject.
 *
 * @param {string} subProjectId - The identifier of the subproject to which the asset belongs.
 * @param {string} assetId - The identifier of the asset to be updated.
 * @param {IAsset} body - The updated details for the asset, conforming to the IAsset interface.
 * @returns {Promise<any>} A Promise that resolves to the data from the successful response.
 * @throws {Error} If an error occurs during the HTTP request.
 */
export const updateAssetDetail = async (
  subProjectId: string,
  assetId: string,
  body: IAsset,
) => {
  try {
    const response = await http.patch(
      GET_PROJECT_ASSET_DETAIL.replace("{subProjectId}", subProjectId).replace(
        "{assetId}",
        assetId,
      ),
      body,
    );
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Removes members from assets associated with a subproject.
 *
 * @param subProjectId - The ID of the subproject.
 * @param body - An object containing tags and users to be removed.
 * @param body.tags - An array of tag IDs to remove from assets.
 * @param body.users - An array of user IDs to remove from assets.
 * @returns A promise that resolves with the response data after successful removal.
 * @throws Throws an error if the removal process fails.
 */
export const removeMembersFromAssets = async (
  subProjectId: string,
  body: { tags: string[]; users: string[] },
): Promise<any> => {
  try {
    const url = REMOVE_ASSET_MEMBER.replace(
      "{subProjectId}",
      subProjectId,
    ).replace("{assetId}", subProjectId);
    const response: AxiosResponse<any> = await http.post(url, body);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Add members in the assets associated with a subproject.
 *
 * @param subProjectId - The ID of the subproject.
 * @param body - An object containing tags and users to be added.
 * @param body.tags - An array of tag IDs to add in the assets.
 * @param body.users - An array of user IDs to add in the assets.
 * @returns A promise that resolves with the response data after successful addition.
 * @throws Throws an error if the addition process fails.
 */
export const addMembersInAssets = async (
  subProjectId: string,
  body: { tags: string[]; users: string[] },
): Promise<any> => {
  try {
    const url = ADD_ASSET_MEMBER.replace(
      "{subProjectId}",
      subProjectId,
    ).replace("{assetId}", subProjectId);
    const response: AxiosResponse<any> = await http.post(url, body);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Fetches equipment timeline data from the server.
 *
 * @param {string} timelineId - The ID of the timeline to fetch.
 * @param {IParams} [params] - Optional parameters for the request.
 * @returns {Promise<ITimelineResponse>} - A promise resolving to the fetched timeline response.
 * @throws {Error} - Throws an error if the request fails.
 */
export const fetchEquipmentTimeline = async (
  timelineId: string,
  params?: IParams, // Make params optional
): Promise<ITimelineResponse> => {
  try {
    const url = GET_TIMELINE.replace("{timelineId}", timelineId);
    const response: AxiosResponse<ITimelineResponse> = await pmHttp.get(
      url,
      params,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Fetches global asset packages from the server.
 *
 * @param {IParams} [params] - Optional parameters for the request.
 * @returns {Promise<IGlobalSearchResponse>} - A promise resolving to the fetched global asset packages response.
 * @throws {Error} - Throws an error if the request fails.
 */

export const fetchGlobalEquipments = async (
  params?: IParams,
): Promise<IGlobalSearchResponse> => {
  try {
    const response: AxiosResponse<IGlobalSearchResponse> = await http.get(
      GET_GLOBAL_ASSET_PKG,
      params,
      SERVICE_UNIQUE_ID.UNIQUE_FETCH_GLOBAL_EQUIPMENTS,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * add Asset Image
 *
 * @returns A Promise that create the new contact.
 * @throws If an error occurs during the request.
 */
export const UploadAssetImage = async (
  projectId: any,
  tagId: string,
  body: any,
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await http.post(
      UPLOAD_ASSET_PROFILE.replace("{projectid}", projectId).replace(
        "{tagid}",
        tagId,
      ),
      body,
      MULTIPART_HEADERS,
    );
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

export const fetchAssetsAiSuggestions = async (
  isPackageRoom: boolean,
  subProjectId: string,
  tagId: string,
  packageId: string,
  requestId: string,
): Promise<any> => {
  try {
    let URL = !isPackageRoom
      ? TAGS_RECOMMENDATION.replace("{sub_project_id}", subProjectId).replace(
          "{tag_id}",
          tagId,
        )
      : PACKAGES_RECOMMENDATION.replace(
          "{sub_project_id}",
          subProjectId,
        ).replace("{package_id}", packageId);

    const response: AxiosResponse<IAISummaryResponse> = await http.get(
      URL,
      {},
      requestId,
    );

    return response?.data;
  } catch (ex) {
    console.log(ex);
  }
};
