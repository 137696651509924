export enum PM_STATUS {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  SKIPPED = "SKIPPED",
  WAITING_FOR_REVIEW = "WAITING FOR REVIEW",
  INPROGRESS = "IN PROGRESS",
  DENIED = "DENIED",
}

export enum PREVENTIVE_MAINTENANCE {
  PM_EXTERNAL = "PM_EXTERNAL",
  PM_INTERNAL = "PM_INTERNAL",
}

export const PM_STATUS_COLORS = {
  [PM_STATUS.COMPLETED]: "#43BD18",
  [PM_STATUS.PENDING]: "#c2c576",
  [PM_STATUS.SKIPPED]: "#e5b567",
  [PM_STATUS.WAITING_FOR_REVIEW]: "#faad14",
  [PM_STATUS.DENIED]: "#f5222d",
};

export enum PREVENTIVE_MAINTENANCE_DOCUMENTS {
  IMAGES = "IMAGES",
  VIDEOS = "VIDEOS",
  PM_REPORTS = "PM_REPORTS",
  PM_INSTRUCTIONS = "PM_INSTRUCTIONS",
  COMMERCIAL_DOCS = "COMMERCIAL_DOCS",
}

export enum PME_ACTION_REQUIRED_ENUM {
  ASSIGNEE = "Assignee",
  APPROVERS = "Approvers",
}

export enum USER_TYPES {
  ADMIN = 1,
  INTERNAL = 2,
  EXTERNAL = 3,
}
