import InfiniteScroll from "react-infinite-scroll-component";
import { Divider, Skeleton, Spin } from "antd";
import { memo, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import NotificationsHeader from "./NotificationsHeader";
import NotificationsDropDownCard from "./NotificationsDropDownCard";
import { INotificationsMainProps } from "./Notification.interface";
import { getUnreadNotificationsCount } from "redux/components/notifications/sources";
import "./Notification.scss";
import {
  actions as notificationActions,
  NotificationTypes,
} from "redux/components/notifications";
import {
  fetchNotifications,
  markNotificationsAsRead,
} from "services/notifications/notifications.service";
import { IUserNotification } from "services/notifications/notifications.interface";
import { IMetaNest } from "redux/interfaces";
import { debounce } from "lodash";

const NotificationsMain: React.FC<INotificationsMainProps> = (
  props: INotificationsMainProps,
) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Track drawer visibility
  const wrapperRef = useRef<HTMLDivElement>(null); // Reference for the component
  const [notificationList, setNotificationList] = useState<IUserNotification[]>(
    [],
  );
  const [meta, setMeta] = useState<IMetaNest | null>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const getAllNotifications = async (limit = 50) => {
    setLoader(true);
    try {
      const response = await fetchNotifications({
        page,
        limit,
        isSystemGenerated: props.type === NotificationTypes.GLOBAL,
      });
      setLoader(false);
      if (page > 1) {
        setNotificationList((prev) => [...prev, ...response.data]);
      } else {
        setNotificationList(response.data);
      }
      setMeta(response.meta);
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  useEffect(() => {
    const debouncedGetAllNotifications = debounce(() => {
      getAllNotifications();
    }, 200);
    debouncedGetAllNotifications();
    return () => {
      debouncedGetAllNotifications.cancel();
    };
  }, [page, props.type]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        !isDrawerOpen
      ) {
        dispatch(notificationActions.toggleNotificationDropdown(false));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch, isDrawerOpen]);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const markAllAsRead = async () => {
    await markNotificationsAsRead({
      ids: [],
      isSystemGenerated: props.type === NotificationTypes.GLOBAL,
    });
    dispatch(getUnreadNotificationsCount());
    getAllNotifications();
  };

  const markAsReadNotifications = async (ids: any[]) => {
    try {
      await markNotificationsAsRead({
        ids,
        isSystemGenerated: props.type === NotificationTypes.GLOBAL,
      });
      dispatch(getUnreadNotificationsCount());

      getAllNotifications();
    } catch (ex) {
      console.log(ex);
    }
  };
  return (
    <Spin spinning={loader}>
      <div className="notifications-main" ref={wrapperRef}>
        <div className="notification-header">
          <NotificationsHeader
            setIsUserDropdownOpen={props.setIsUserDropdownOpen}
            setIsSystemDropdownOpen={props.setIsSystemDropdownOpen}
            markAllAsRead={markAllAsRead}
            fullScreenActive={props.fullScreenActive}
            type={props.type}
          />
        </div>
        <div
          className={`notifications-body ${
            !props?.fullScreenActive ? "fixed-height" : ""
          }`}
          id="scrollableDiv"
        >
          <InfiniteScroll
            dataLength={notificationList.length}
            next={() => {
              setPage((prevPage) => prevPage + 1);
            }}
            hasMore={notificationList.length < (meta?.totalItems || 0)}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            endMessage={
              notificationList.length >= (meta?.totalItems || 0) && (
                <Divider plain>It is all, nothing more.</Divider>
              )
            }
            scrollableTarget="scrollableDiv"
            height={props.fullScreenActive ? "calc(100vh - 170px)" : 500}
          >
            {notificationList.map((notification) => (
              <NotificationsDropDownCard
                {...notification}
                key={notification.id}
                markAsReadNotification={markAsReadNotifications}
                isPageNotification={props.fullScreenActive}
                onDrawerOpen={handleDrawerOpen}
                onDrawerClose={handleDrawerClose}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </Spin>
  );
};

const arePropsEqual = (
  prevProps: INotificationsMainProps,
  nextProps: INotificationsMainProps,
) => {
  return prevProps.type === nextProps.type;
};

export default memo(NotificationsMain, arePropsEqual);
