import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AlectifyCarouselProps } from "./AlectifyCarousel.interface";
import { Image, message, Skeleton, Upload } from "antd";
import { debounce, isEmpty } from "lodash";
import {
  CloudUploadOutlined,
  LoadingOutlined,
  PlayCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { UploadFile } from "antd/lib";
import { MESSAGES } from "constants/messages";
import { FOLDER_NAME } from "enums/folder-type.enum";
import { uploadPrevMaintenanceDocuments } from "services/pm-internal/pm-internal.service";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { UploadChangeParam } from "antd/es/upload";
import { actions } from "redux/components/pm-internal";
import RightArrowIcon from "components/icons/RightArrowIcon";
import { LeftArrowIcon } from "components/icons";
import "./AlectifyCarousel.scss";
import { getPmInternalAttachments } from "redux/components/pm-internal/sources";

const AlectifyCarousel: React.FC<AlectifyCarouselProps> = ({
  preview = false,
  itemsPerPage = 5,
  fetchMoreAttachments,
  recordID,
  details,
  videoWidth,
  videoHeight,
}) => {
  const { attachmentsMedia, attachmentLoader } = useSelector(
    (state: IRootState) => state.pmInternal,
  );

  const dispatch = useDispatch();
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const user = useSelector((state: IRootState) => state.auth);
  const [sliderKey, setSliderKey] = useState(0);
  const handleNext = () => {
    const isLastSlide = currentSlide === attachmentsMedia.data.length - 1;
    if (
      isLastSlide &&
      attachmentsMedia.meta.currentPage < attachmentsMedia.meta.totalPages
    ) {
      const nextPage = attachmentsMedia.meta.currentPage + 1;
      if (fetchMoreAttachments && recordID) {
        fetchMoreAttachments(nextPage);
      }
    }
    sliderRef.current?.slickNext();
  };

  const handlePrev = () => {
    const isFirstSlide = currentSlide === 0;
    if (isFirstSlide && attachmentsMedia.meta.currentPage > 1) {
      const prevPage = attachmentsMedia.meta.currentPage - 1;
      if (fetchMoreAttachments && recordID) {
        fetchMoreAttachments(prevPage);
      }
    }
    sliderRef.current?.slickPrev();
  };

  const uploadDocuments = async (fileList: UploadFile[]) => {
    try {
      dispatch(actions.setAttachmentLoader(true));

      if (details) {
        message.loading(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_UPLOADING);
        const formData = new FormData();
        fileList.forEach((file) => {
          formData.append(
            FOLDER_NAME.DOCUMENT_UPLOAD,
            file.originFileObj as any,
          );
        });

        formData.append("userId", user?.user?.id);
        formData.append("projectId", details.project?.id || "-");
        formData.append("subProjectId", details.subProject.id);

        await uploadPrevMaintenanceDocuments(details.id, formData);
        message.success(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_UPLOADED);
      }
    } catch (error) {
      message.error(MESSAGES.FILE_UPLOAD_MESSAGES.DOCUMENTS_ERROR);
      throw error;
    } finally {
      dispatch(getPmInternalAttachments(details?.id));

      dispatch(actions.setAttachmentLoader(false));
      if (fetchMoreAttachments && recordID) {
        fetchMoreAttachments();
      }
    }
  };

  // This method only handles the upload without interfering with the slider
  const handleUploadChange = debounce((info: UploadChangeParam) => {
    uploadDocuments(info.fileList);
  }, 300);

  const defaultSettings = {
    customPaging: (i: number) => {
      const thumbnailCount = attachmentsMedia?.data?.length || 0;
      const shouldShowUpload =
        thumbnailCount === 1 ||
        thumbnailCount === 2 ||
        (thumbnailCount >= 3 && i === thumbnailCount - 1);

      const file = attachmentsMedia?.data?.[i];
      const isVideo = file?.fileType === "video/mp4";

      return (
        <div className="d-flex align-items-center justify-content-center">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>
            {attachmentLoader ? (
              <Skeleton.Image
                active
                style={{ width: "auto", height: "auto" }}
              />
            ) : isVideo ? (
              <div className="video-thumbnail-container">
                <PlayCircleFilled
                  style={{
                    fontSize: "20px",
                  }}
                />
              </div>
            ) : (
              <img
                src={file?.filePath}
                alt={`Thumbnail ${i + 1}`}
                style={{
                  width: "50px",
                  height: "50px",
                  objectFit: "cover",
                  borderRadius: "5px",
                }}
              />
            )}
          </a>
          {shouldShowUpload && i === thumbnailCount - 1 && (
            <div
              className={`avatar-uploader plus-icon ${
                preview ? "" : "plus-white-icon"
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                showUploadList={false}
                onChange={handleUploadChange}
                beforeUpload={() => false}
                multiple
                fileList={[]}
              >
                <div>
                  {attachmentLoader ? <LoadingOutlined /> : <PlusOutlined />}
                </div>
              </Upload>
            </div>
          )}
        </div>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (_current: number, next: number) => {
      setCurrentSlide(next);
    },
  };

  useEffect(() => {
    if (attachmentsMedia?.data) {
      setCurrentSlide(0);
      sliderRef.current?.slickGoTo(0);
      setSliderKey((prev) => prev + 1);
    }
  }, [attachmentsMedia]);

  const totalItems = attachmentsMedia?.meta?.totalItems || 0;

  const globalSlideIndex =
    (attachmentsMedia?.meta?.currentPage - 1) * itemsPerPage + currentSlide + 1;
  return (
    <div className="carousel-container">
      {!isEmpty(attachmentsMedia?.data) ? (
        <>
          <Slider key={sliderKey} {...defaultSettings} ref={sliderRef}>
            {attachmentsMedia?.data?.map((slide: any, index: number) => (
              <div key={`slide-${index}`} className="carousel-item">
                {attachmentLoader ? (
                  <Skeleton.Image
                    active
                    style={{ width: "20rem", height: "21rem" }}
                  />
                ) : (
                  <div>
                    {slide.fileType === "video/mp4" ? (
                      <div className="video-carousel-container">
                        <video
                          src={slide.filePath}
                          controls
                          className="carousel-video"
                          style={{
                            width: videoWidth,
                            height: videoHeight,
                          }}
                        />
                      </div>
                    ) : (
                      <Image
                        preview={preview}
                        className="img-carousel"
                        src={slide.filePath}
                        alt={`Slide ${index + 1}`}
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
          </Slider>
          {/* carousel navigation buttons  */}
          <div className="carousel-navigation">
            <div className="slick-prev custom-arrow" onClick={handlePrev}>
              <LeftArrowIcon stroke={"#fff"} />
            </div>

            <div className="slick-next custom-arrow" onClick={handleNext}>
              <RightArrowIcon stroke={"#fff"} />
            </div>
          </div>

          {!attachmentLoader && (
            <div className="image-counter">
              {globalSlideIndex}/{totalItems}
            </div>
          )}
        </>
      ) : (
        <div className="empty-image">
          <Upload
            name="avatar"
            listType="picture-card"
            showUploadList={false}
            onChange={handleUploadChange}
            beforeUpload={() => false}
            multiple
            fileList={[]}
            disabled={details?.isFuture}
          >
            <div className="upload-items">
              <div>
                {attachmentLoader ? (
                  <LoadingOutlined />
                ) : (
                  <CloudUploadOutlined className="icon-font-size-20" />
                )}
              </div>
              <span className="upload-msg-container">
                {details && !details.isFuture
                  ? "No image uploaded, click here to upload"
                  : "No image uploaded"}
              </span>
            </div>
          </Upload>
        </div>
      )}
    </div>
  );
};

export default AlectifyCarousel;
