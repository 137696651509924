import { Space, Tag, Tooltip } from "antd";
import AlectifyText from "static/texts.json";
import { PartitionOutlined } from "@ant-design/icons";

interface MultiAssetTagProps {
  count?: number;
  text?: string;
  showGenericName?: boolean;
}

const MultiAssetTag: React.FC<MultiAssetTagProps> = ({
  count,
  text,
  showGenericName,
}) => {
  return (
    <Tag color={"#0da596"} className="round-tag">
      <Space size={3}>
        <Tooltip title={"Multiple Asset"}>
          <PartitionOutlined />
        </Tooltip>
        Multiple Assets
      </Space>
    </Tag>
  );
};

MultiAssetTag.defaultProps = {
  text: AlectifyText.GENERIC,
  showGenericName: true,
};

export default MultiAssetTag;
