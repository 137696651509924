import { Space, Tag } from "antd";
import TasksColoredIcon from "../../icons/TasksColoredIcon";
import AlectifyText from "static/texts.json";

const TaskTag: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Tag color={"#E3F0FF"} style={{ color: "#246ABA" }} className="round-tag">
      <Space>
        <TasksColoredIcon isTimeline />
        <b>{text || AlectifyText.TASK}</b>
      </Space>
    </Tag>
  );
};

export default TaskTag;
