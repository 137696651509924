import {
  ITableFilterInitialState,
  ITableFilterTypeEnum,
} from "./TableFilter.interface";

export const tableFilterInitialState: ITableFilterInitialState = {
  tableFilters: {
    filterType: ITableFilterTypeEnum.OPEN,
    createdAt: null,
    dueDate: { orderField: "dueDate", orderBy: "DESC" },
    status: null,
    taskCategory: null,
    orderTypeTab: "all",
    statusShifterButtons: ["PENDING", "WAITING FOR REVIEW", "DENIED"],
  },
};
