import { Dispatch } from "react";
import { ISubProject } from "redux/components/sub-project";
import { IMasterProject } from "redux/components/master-project";
import { IParams, SourceActionType } from "redux/interfaces";
import { actions } from "..";
import { fetchComments } from "services/comments/comments.service";

export const setActiveSubProject: SourceActionType = (payload: ISubProject) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setActiveSubProject(payload));
  };
};

export const setActiveMasterProject: SourceActionType = (
  payload: IMasterProject,
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setActiveMasterProject(payload));
  };
};

export const setActiveCalendarType: SourceActionType = (payload: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setActiveCalendarType(payload));
  };
};

export const setMasterProjectSidebar: SourceActionType = (payload: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setMasterProjectSidebar(payload));
  };
};

export const setAssetDetailSidebar: SourceActionType = (payload: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setAssetDetailSidebar(payload));
  };
};

export const setShowMyWorkOrders: SourceActionType = (payload: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setShowMyWorkOrders(payload));
  };
};

export const setShowTeamInventory: SourceActionType = (payload: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setShowTeamInventory(payload));
  };
};

export const setSearchedText: SourceActionType = (payload: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setSearchedText(payload));
  };
};

export const setUploadDocuments: SourceActionType = (payload: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setUploadDocuments(payload));
  };
};

export const deleteUploadedDocument: SourceActionType = (uid: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.deleteUploadedDocument(uid));
  };
};

export const setShowCalendarMyWorkOrders: SourceActionType = (
  payload: boolean,
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setShowCalendarMyWorkOrders(payload));
  };
};

export const setSimpleTableDetail: SourceActionType = (payload: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setSimpleTableDetail(payload));
  };
};

export const getComments: SourceActionType = (
  subProjectId: string,
  referenceId: string,
  params?: IParams | any,
  isSystemGenerated?: boolean,
  append?: boolean,
) => {
  if (isSystemGenerated) {
    params.is_system_generated = isSystemGenerated;
  }
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.setCommentsLoader(true));
    try {
      const response = await fetchComments(subProjectId, referenceId, params);
      if (append) {
        dispatch(actions.appendComments(response));
      } else {
        dispatch(actions.setComments(response));
      }
      dispatch(actions.setCommentsLoader(false));
    } catch (ex) {
      dispatch(actions.setCommentsLoader(false));
    }
  };
};
