import { isEmpty } from "lodash";
import { IDataRoundAnalytics } from "services/data-round/data-round.interface";
import dayjs from "dayjs";

export const getChartOptions = (
  analytics: IDataRoundAnalytics[] | null,
): any => ({
  chart: {
    id: "realtime",
    height: 400,
    type: "line",
    toolbar: {
      show: true,
      tools: {
        download: true,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false,
      },
      export: {
        csv: {
          headerCategory: "Shift Dates",
          dateFormatter: (val: string) => dayjs(val).format("MMM D, YYYY"),
        },
      },
    },
  },
  grid: {
    show: !isEmpty(analytics),
  },
  stroke: {
    width: 4,
    curve: "smooth",
  },
  markers: {
    size: 5,
    colors: ["#0500FF"],
    strokeWidth: 2,
    shape: "circle",
  },
  xaxis: {
    type: "category",
    categories: analytics
      ? analytics.map((item) => dayjs(item.shift_date).format("MMM D, YYYY"))
      : [],
    title: {
      text: "Shift Dates",
    },
    tooltip: {
      enabled: false,
    },
    labels: {
      rotate: -45,
      style: {
        fontSize: "10px",
      },
      show: true,
    },
  },
  yaxis: {
    title: {
      text: "Readings",
    },
    labels: {
      formatter: (val: string) => val,
    },
  },
  legend: {
    position: "top",
  },
  colors: ["#0500FF"],
  noData: {
    text: "Currently, there's no data to display.",
    style: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "grey",
    },
  },
});

export const getChartSeries = (analytics: IDataRoundAnalytics[] | null) => [
  {
    name: "Readings",
    data: analytics
      ? analytics
          .sort(
            (a, b) =>
              dayjs(a.shift_date).valueOf() - dayjs(b.shift_date).valueOf(),
          )
          .map((item) => {
            const numericValue = item.reading_key.replace(/[^0-9.]/g, "");
            return numericValue ? parseFloat(numericValue) : 0;
          })
      : [],
  },
];
