import { useState } from "react";
import { Col, Form, Row, Spin, notification, Typography } from "antd";
import {
  IRefillSparePartProps,
  IRefillSparePartStates,
} from "./RefillSparePart.interface";
import { displayDateTime, getFullUserName, renderAmount } from "utils/helpers";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { refillSparePart } from "services/spare-parts/spare-parts.service";
import { AlectifyDetailCardText } from "../detail-card";
import { BASIC_REQUIRED_VALIDATION_RULES } from "constants/index";
import AlectifyText from "static/texts.json";
import AlectifyModal from "../modal";
import AlectifyDrawer from "components/drawer";
import SparePartDetail from "../spare-part-detail";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyQuantityInput from "../quantity-input";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import { CloseOutlined } from "@ant-design/icons";
import AlectifyInput from "../input";
import { MESSAGES } from "constants/messages";
import { MODAL_NAMES } from "constants/modal.constants";
import SuccessfulMessageModal from "../pop-message/SuccessfulMessageModal";

const initialState: IRefillSparePartStates = {
  loading: false,
};

const RefillSparePartForm: React.FC<IRefillSparePartProps> = (
  props: IRefillSparePartProps,
) => {
  /*  const [sparePart, setSparePart] = useState<IProjectSparePart>(
    props.sparePart,
  ); */
  const [state, setState] = useState<IRefillSparePartStates>({
    ...initialState,
  });
  const [newQty, setNewQty] = useState<number>(0);
  const [totalQty, setTotalQty] = useState<number>(0);
  const [newAvgPrice, setNewAvgPrice] = useState<string>("");

  const onSubmit = async (values: any) => {
    try {
      await props.FormInstance.validateFields();
      if (values.quantityInHand === "0") {
        props.FormInstance.setFields([
          {
            name: "quantityInHand",
            errors: ["Quantity should be greater than 0"],
          },
        ]);
        return;
      } else if (
        parseInt(values.quantityInHand) >
        props.sparePart?.manageOrders?.[0].remainingQuantity
      ) {
        props.FormInstance.setFields([
          {
            name: "quantityInHand",
            errors: [MESSAGES.SPARE_PART_MESSAGES.OVER_PENDING_ORDERS_QTY],
          },
        ]);
        return;
      }
      setState({
        ...state,
        loading: true,
      });
      await refillSparePart(props.sparePart.id, {
        comments: values?.comments,
        restockQty: newQty,
        quantity: totalQty || props.sparePart.remainingQuantity,
        price: calculateAvgPrice(),
        manageOrderId: props.sparePart?.manageOrders?.[0]?.id || "",
      });
      const newRemainingQty =
        props.sparePart.manageOrders[0].remainingQuantity - newQty;
      props.FormInstance.resetFields();
      setState({
        ...state,
        loading: false,
      });
      ModalServiceInstance.close(AlectifyModal, {
        name: DRAWER_CONSTANTS.DRAWER_REFILL_SPARE_PART,
      });
      props.fetchSpareParts();
      if (newRemainingQty === 0) {
        setTimeout(() => {
          openSuccessMessageModal();
        }, 0);
      }
    } catch (ex: any) {
      console.log(ex);
      setState({
        ...state,
        loading: false,
      });
      if (ex?.errorFields && ex?.errorFields.length > 0) return;
      notification.error({
        message: "Cannot refill spare parts",
      });
      props.FormInstance.resetFields();
    }
  };

  const openSuccessMessageModal = () => {
    ModalServiceInstance.open(AlectifyModal, {
      className: "alectify-success-modal",
      name: MODAL_NAMES.SUCCESSFUL_MESSAGE_MODAL,
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.SUCCESSFUL_MESSAGE_MODAL,
        });
      },
      footer: false,
      closeIcon: false,
      children: (
        <SuccessfulMessageModal
          text={
            "Order is completed and it will be removed from the “Order Management”. You can view the details under Spare Parts History"
          }
        />
      ),
    });
  };

  const calculateNewQty = (newValue: string) => {
    const numericValue = parseInt(newValue);
    if (numericValue > 0) {
      const newQuantity = numericValue;
      const totalQuantity = props.sparePart.remainingQuantity + newQuantity;

      const newAvgPriceNumeric =
        typeof newAvgPrice === "string" ? parseInt(newAvgPrice) : newAvgPrice;
      const oldPriceNumeric =
        typeof props.sparePart.price === "string"
          ? parseInt(props.sparePart.price)
          : props.sparePart.price;

      const newPrice = newAvgPriceNumeric || oldPriceNumeric;

      const calculatedPrice =
        (newQuantity * newPrice +
          props.sparePart.remainingQuantity * oldPriceNumeric) /
        totalQuantity;

      setNewQty(newQuantity);
      setTotalQty(totalQuantity);

      if (newAvgPriceNumeric) {
        setNewAvgPrice(calculatedPrice.toString());
      }
    } else {
      setNewQty(0);
      setTotalQty(0);
    }
  };

  /*   const onChangePrice = (value: number | null) => {
    if (value) {
      if (newQty) {
        const oldPriceNumeric =
          typeof sparePart.price === "string"
            ? parseInt(sparePart.price)
            : sparePart.price;
        const calculatedPrice =
          (newQty * value + sparePart.remainingQuantity * oldPriceNumeric) /
          totalQty;
        setNewAvgPrice(calculatedPrice.toString());
      } else {
        setNewAvgPrice(value.toString());
      }
    } else {
      setNewAvgPrice("0");
    }
  }; */

  const calculateAvgPrice = () => {
    const unitPrice =
      typeof order?.unitPrice === "string"
        ? parseInt(order?.unitPrice)
        : order?.unitPrice;
    if (order?.unitPrice) {
      if (newQty) {
        const oldPriceNumeric =
          typeof props.sparePart.price === "string"
            ? parseInt(props.sparePart.price)
            : props.sparePart.price;

        const calculatedPrice =
          (newQty * unitPrice +
            props.sparePart.remainingQuantity * oldPriceNumeric) /
          totalQty;
        return calculatedPrice;
      } else {
        return unitPrice;
      }
    } else {
      return unitPrice;
    }
  };

  /*   const getSparePartDetail = async () => {
    try {
      const response = await fetchSparePartsDetail(props.sparePart.id);
      setSparePart(response.data);
    } catch (error) {
      console.log(error);
    }
  }; */

  // useEffect(() => {
  //   // getSparePartDetail();
  // }, [props.sparePart.id]);

  const order = props.sparePart?.manageOrders?.[0];

  return (
    <Spin spinning={state.loading}>
      <Row justify="start" gutter={24}>
        <Col span={24}>
          <AlectifyDetailCardText
            heading={AlectifyText.ORDER_DETAILS}
            label={AlectifyText.PO_NUMBER}
            value={order?.poNumber}
          />
          <AlectifyDetailCardText
            label={AlectifyText.PO_DATE}
            value={displayDateTime(order?.orderedDate)}
          />
          <AlectifyDetailCardText
            label={AlectifyText.ORDERED_BY}
            value={getFullUserName(order.orderedBy)}
          />
          <AlectifyDetailCardText
            label={AlectifyText.ORDER_QUANTITY}
            value={order.quantity}
          />
          <AlectifyDetailCardText
            label={AlectifyText.UNIT_PRICE}
            value={renderAmount(order.unitPrice)}
          />
          <AlectifyDetailCardText
            label={AlectifyText.TOTAL_PRICE_CALCULATED}
            value={renderAmount(order.unitPrice * order.quantity)}
          />
          <Typography.Title
            level={5}
            className="alectify-bordered-row-text-title"
          >
            {AlectifyText.COMMENTS}
          </Typography.Title>
          <div className="alectify-bordered-row-text">
            <Typography.Title level={5} style={{ width: "100%" }}>
              {order?.comments}
            </Typography.Title>
          </div>
          <Typography.Title
            level={5}
            className="alectify-bordered-row-text-title"
          >
            {AlectifyText.ORDER_STATUS}
          </Typography.Title>
          <Row>
            <Col span={12}>
              <AlectifyDetailCardText
                label={AlectifyText.RECEIVED_SO_FAR}
                value={order.quantity - order?.remainingQuantity || "0"}
              />
            </Col>
            <Col span={12}>
              <AlectifyDetailCardText
                label={AlectifyText.PENDING_QTY}
                value={order?.remainingQuantity}
              />
            </Col>
          </Row>

          <AlectifyDetailCardText
            label={`${AlectifyText.PART_NUMBER}: ${props.sparePart?.sparePart?.partNumber}`}
            value={"View Part Information >"}
            linkValue
            className="mb-30"
            onClick={() => {
              DrawerServiceInstance.open(AlectifyDrawer, {
                width: 900,
                title: props.sparePart?.sparePart?.partNumber,
                name: DRAWER_CONSTANTS.DRAWER_SPARE_PART_DETAIL,
                closable: true,
                closeIcon: <CloseOutlined />,
                onClose: () =>
                  DrawerServiceInstance.close(AlectifyDrawer, {
                    name: DRAWER_CONSTANTS.DRAWER_SPARE_PART_DETAIL,
                  }),
                children: (
                  <SparePartDetail
                    sparePart={props.sparePart}
                    showSimilarSparePart
                  />
                ),
              });
            }}
          />

          <div className="grey-bg-wrapper">
            <Form
              name="refillSparePart"
              layout="vertical"
              form={props.FormInstance}
              onFinish={onSubmit}
              initialValues={{}}
            >
              <AlectifyQuantityInput
                name="quantityInHand"
                label={
                  <>
                    {AlectifyText.RECEIVED_QUANTITY}
                    <div className="form-label-extra">
                      <Typography.Text style={{ color: "#787878" }}>
                        ({AlectifyText.PENDING_QTY}:{" "}
                        {order?.remainingQuantity || 0})
                      </Typography.Text>
                    </div>
                  </>
                }
                max={order.remainingQuantity}
                maxQtyErrorMsg={
                  MESSAGES.SPARE_PART_MESSAGES.OVER_PENDING_ORDERS_QTY
                }
                placeholder=""
                rules={BASIC_REQUIRED_VALIDATION_RULES}
                form={props.FormInstance}
                extra={
                  <>
                    <Row
                      justify="space-between"
                      align="middle"
                      className="mt-5"
                    >
                      <Col span={12}></Col>
                      <Col span={12} className="text-align-right">
                        <Typography.Text className="green-text">
                          Remaining Quantity:{" "}
                          {order?.remainingQuantity - newQty}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </>
                }
                onChange={calculateNewQty}
              />
              <AlectifyInput
                name="comments"
                label={AlectifyText.COMMENTS}
                type="textarea"
                placeholder=""
                // rules={BASIC_REQUIRED_VALIDATION_RULES}
              />
            </Form>
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default RefillSparePartForm;
