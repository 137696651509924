import { IReduxActionResponse } from "redux/interfaces";
import { ISubProject } from "redux/components/sub-project";
import { IMasterProject } from "redux/components/master-project";
import { UploadFile } from "antd/lib";
import { commonInitialState } from "redux/components/common/common.initialState";
import { ICommentsState, ICommonState } from "../common.interface";
import { ICommentItem } from "components/shared/comments/Comments.interface";

export const setActiveSubProject = (
  state: any,
  action: IReduxActionResponse<ISubProject>,
) => {
  const { payload } = action;
  return {
    ...state,
    activeSubProject: payload,
  };
};

export const setActiveMasterProject = (
  state: any,
  action: IReduxActionResponse<IMasterProject>,
) => {
  const { payload } = action;
  return {
    ...state,
    activeMasterProject: payload,
    previousActiveMasterProject: state.activeMasterProject,
  };
};

export const setActiveCalendarType = (
  state: any,
  action: IReduxActionResponse<IMasterProject>,
) => {
  const { payload } = action;
  return {
    ...state,
    activeCalendarType: payload,
  };
};

export const logout = (state: any, action: IReduxActionResponse<any>) => {
  return {
    ...state,
    activeMasterProject: null,
  };
};

export const setMasterProjectSidebar = (
  state: any,
  action: IReduxActionResponse<boolean>,
) => {
  const { payload } = action;
  return {
    ...state,
    masterProjectSidebar: payload,
  };
};

export const setAssetDetailSidebar = (
  state: any,
  action: IReduxActionResponse<boolean>,
) => {
  const { payload } = action;
  return {
    ...state,
    assetDetailSidebar: payload,
  };
};

export const setShowMyWorkOrders = (
  state: any,
  action: IReduxActionResponse<boolean>,
) => {
  const { payload } = action;
  return {
    ...state,
    showMyWorkOrders: payload,
  };
};

export const setShowTeamInventory = (
  state: any,
  action: IReduxActionResponse<boolean>,
) => {
  const { payload } = action;
  return {
    ...state,
    showTeamInventory: payload,
  };
};

export const setSearchedText = (
  state: any,
  action: IReduxActionResponse<boolean>,
) => {
  const { payload } = action;
  return {
    ...state,
    searchedText: payload,
  };
};

export const setShowCalendarMyWorkOrders = (
  state: any,
  action: IReduxActionResponse<boolean>,
) => {
  const { payload } = action;
  return {
    ...state,
    showCalendarMyWorkOrders: payload,
  };
};

export const setUploadDocuments = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  const { payload } = action;
  return {
    ...state,
    uploadDocuments: payload,
  };
};

export const deleteUploadedDocument = (
  state: any,
  action: IReduxActionResponse<string>,
) => {
  const { payload } = action;
  return {
    ...state,
    uploadDocuments: state.uploadDocuments.filter(
      (file: UploadFile) => file.uid !== payload,
    ),
  };
};

export const setCommentsLoader = (
  state: ICommonState,
  action: IReduxActionResponse<boolean>,
): ICommonState => {
  return {
    ...state,
    comments: {
      ...state.comments,
      fetching: action.payload ?? false,
    },
  };
};

export const setComments = (
  state: ICommonState,
  action: IReduxActionResponse<ICommentsState>,
): ICommonState => {
  return {
    ...state,
    comments: {
      ...state.comments,
      data: action.payload?.data ?? [],
      meta: action.payload?.meta ?? null,
    },
  };
};

export const appendComments = (
  state: ICommonState,
  action: IReduxActionResponse<ICommentsState>,
) => {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      comments: {
        data: [...state.comments.data, ...payload?.data],
        meta: payload?.meta ?? null,
      },
    };
  }
};

export const pushNewComment = (
  state: ICommonState,
  action: IReduxActionResponse<ICommentItem>,
): ICommonState => {
  if (action.payload) {
    return {
      ...state,
      comments: {
        ...state.comments,
        data: [action.payload, ...(state.comments.data ?? [])],
      },
    };
  }
  return state;
};

export const resetCommonState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return {
    ...commonInitialState,
  };
};

export const setSimpleTableDetail = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  const { payload } = action;
  return {
    ...state,
    simpleTableState: {
      pageIsOnMaintenanceDetail:
        payload.pageIsOnMaintenanceDetail !== undefined
          ? payload.pageIsOnMaintenanceDetail
          : state.simpleTableState.pageIsOnMaintenanceDetail,
      page:
        payload.page !== undefined ? payload.page : state.simpleTableState.page,
      per_page:
        payload.per_page !== undefined
          ? payload.per_page
          : state.simpleTableState.per_page,
    },
  };
};
