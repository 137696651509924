import { memo, useEffect, useRef } from "react";
import { Tooltip } from "antd";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import { calculateCitationScore, extractFileName } from "utils/helpers";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import PreviewPdf from "components/shared/preview-pdf";
import PdfIcon from "components/icons/PdfIcon";
import { ChatBotIcon } from "components/icons";
import {
  IChatBotItems,
  IChatBotSourceProps,
  ISources,
} from "./Chatbot.interface";
import TypingIndicator from "components/typing-indicator";
import AlectifyText from "static/texts.json";
import "./Chatbot.scss";
import { isEmpty } from "lodash";
import FileIcon from "components/icons/FileIcon";

const ChatBotSource: React.FC<IChatBotSourceProps> = ({ data, ref }) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [data.chatbotItems]);
  return (
    <div ref={chatContainerRef} style={{ overflowY: "auto" }}>
      {data.chatbotItems?.map((message: IChatBotItems, index) => {
        const isUser = message?.role === "user";
        return (
          <div key={index} className="chatbot-conversion-container">
            <div className={isUser ? "user-question" : "received-message"}>
              <div className="mt-5">{!isUser && <ChatBotIcon />}</div>
              <div className={isUser ? "user-text" : "assistant-text"}>
                {!message?.temporary ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: message?.content || AlectifyText.DATABASE_ERROR,
                    }}
                  />
                ) : (
                  <TypingIndicator text={"Typing"} />
                )}
              </div>
            </div>
            {!isEmpty(message?.sourceDocuments) &&
              message?.sourceDocuments
                ?.filter((source: any) => Object.keys(source).length > 0) // Exclude empty objects
                .map((source: ISources, sourceIndex: number) => {
                  // eslint-disable-next-line
                  if (calculateCitationScore(source)) {
                    return (
                      <div className="read-page-container" key={sourceIndex}>
                        <h3>
                          Read Page No:{" "}
                          <span>{source?.metadata?.page_number || "-"}</span>
                        </h3>
                        <Tooltip title={source?.metadata?.filename || "-"}>
                          <div
                            className="pdf-container"
                            onClick={() => {
                              ModalServiceInstance.open(AlectifyModal, {
                                title: `${extractFileName(
                                  source?.metadata?.filename,
                                )}- Preview`,
                                name: MODAL_NAMES.PREVIEW_PDF_MODAL,
                                width: 1500,
                                centered: true,
                                footer: null,
                                className: "alectify-pdf-viewer-container",
                                onCancel: () => {
                                  ModalServiceInstance.close(AlectifyModal, {
                                    name: MODAL_NAMES.PREVIEW_PDF_MODAL,
                                  });
                                },
                                children: (
                                  <PreviewPdf
                                    documentUrl={source?.metadata?.file_path}
                                    pageNumber={source?.metadata?.page_number}
                                    zoom={80}
                                  />
                                ),
                              });
                            }}
                          >
                            <div className="icon-font-size-16 mr-5">
                              {source?.metadata?.filename
                                ?.toLowerCase()
                                .endsWith(".pdf") ? (
                                <PdfIcon />
                              ) : (
                                <FileIcon />
                              )}
                            </div>
                            <p>{extractFileName(source?.metadata?.filename)}</p>
                          </div>
                        </Tooltip>
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                })}
          </div>
        );
      })}
    </div>
  );
};

export default memo(ChatBotSource);
