import { Form, Spin } from "antd";
import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlectifyTable from "components/shared/table";
import useDocumentsColumn from "./effects/useDocumentsColumn";
import { IDocumentsTableProps } from "./DocumentsTable.interface";
import { downloadDocumentInZipAction } from "redux/components/documents/sources";
import { IDocuments } from "redux/components/documents";
import "./DocumentTable.scss";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import { MODAL_NAMES } from "constants/modal.constants";
import DeleteDocument from "components/shared/delete-document";
import AlectifyModal from "components/shared/modal";
import EditDocument from "components/shared/edit-document";
import RestoreDocument from "components/shared/restore-document";
import { DocumentEditTypes } from "components/shared/edit-document/EditDocument.interface";

const DocumentsTable: React.FC<IDocumentsTableProps> = (
  props: IDocumentsTableProps,
) => {
  const dispatch = useDispatch();
  const [EditFormInstance] = Form.useForm();
  const [DeleteFormInstance] = Form.useForm();
  const [RestoreFormInstance] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const { fetching } = useSelector(({ documents }) => documents);

  const downloadDocumentsInZip = async (s3Keys: string[]) => {
    dispatch(downloadDocumentInZipAction(s3Keys));
  };
  const displayRowClass = (record: any) => {
    return !record.is_active ? "disable-row-allow-actions" : "";
  };

  const onRowSelectionAction = (selectedRows: any, newSelectedRowKeys: any) => {
    const selectedKeys = selectedRows.map((row: any) => row?.document_id);
    setSelectedRowKeys(selectedKeys);
  };

  const onProjectDocumentDelete = async (record: IDocuments) => {
    try {
      DeleteFormInstance.resetFields();
      ModalServiceInstance.open(AlectifyModal, {
        title: `Delete ${record?.file_name}`,
        name: MODAL_NAMES.DELETE_DOCUMENT_MODAL,
        width: 500,
        okText: "Save Changes",
        onOk: () => {
          DeleteFormInstance.submit();
        },
        destroyOnClose: true,
        onCancel: () => {
          ModalServiceInstance.close(AlectifyModal, {
            name: MODAL_NAMES.DELETE_DOCUMENT_MODAL,
          });
        },
        children: (
          <DeleteDocument
            comment={""}
            type={record.document_type as any}
            documentId={record.document_id}
            form={DeleteFormInstance}
            fileName={record.file_name}
            subProjectId={record.project}
            successCb={() => {
              props.getDocuments(props.optionsPersisted);
              setSelectedRowKeys([]);
              ModalServiceInstance.close(AlectifyModal, {
                name: MODAL_NAMES.DELETE_DOCUMENT_MODAL,
              });
            }}
          />
        ),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onProjectDocumentEdit = async (
    record: IDocuments,
    editType: DocumentEditTypes,
  ) => {
    try {
      EditFormInstance.resetFields();
      ModalServiceInstance.open(AlectifyModal, {
        title: `Edit ${record?.file_name}`,
        name: MODAL_NAMES.EDIT_DOCUMENT_MODAL,
        width: 500,
        okText: "Save Changes",
        onOk: async () => {
          EditFormInstance.submit();
        },
        onCancel: () => {
          ModalServiceInstance.close(AlectifyModal, {
            name: MODAL_NAMES.EDIT_DOCUMENT_MODAL,
          });
        },
        destroyOnClose: true,
        children: (
          <EditDocument
            comment={record.comment}
            type={record.document_type as any}
            version={record.version_number}
            documentId={record.document_id}
            form={EditFormInstance}
            fileName={record.file_name}
            subProjectId={record.project}
            editType={editType}
            successCb={() => {
              props.getDocuments(props.optionsPersisted);
              ModalServiceInstance.close(AlectifyModal, {
                name: MODAL_NAMES.EDIT_DOCUMENT_MODAL,
              });
            }}
          />
        ),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onProjectDocumentRestore = async (record: IDocuments) => {
    try {
      RestoreFormInstance.resetFields();
      ModalServiceInstance.open(AlectifyModal, {
        title: `Restore ${record?.file_name}`,
        name: MODAL_NAMES.RESTORE_DOCUMENT_MODAL,
        width: 500,
        okText: "Save Changes",
        onOk: async () => {
          RestoreFormInstance.submit();
        },
        onCancel: () => {
          ModalServiceInstance.close(AlectifyModal, {
            name: MODAL_NAMES.RESTORE_DOCUMENT_MODAL,
          });
        },
        destroyOnClose: true,
        children: (
          <RestoreDocument
            comment={""}
            type={record.document_type as any}
            documentId={record.document_id}
            form={RestoreFormInstance}
            fileName={record.file_name}
            subProjectId={record.project}
            successCb={() => {
              props.getDocuments(props.optionsPersisted);
              ModalServiceInstance.close(AlectifyModal, {
                name: MODAL_NAMES.RESTORE_DOCUMENT_MODAL,
              });
            }}
          />
        ),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const columns = useDocumentsColumn({
    ...props,
    onEdit: onProjectDocumentEdit,
    onDelete: onProjectDocumentDelete,
    onRestore: onProjectDocumentRestore,
  });

  return (
    <Spin spinning={fetching}>
      <AlectifyTable
        columns={columns}
        loading={props?.document?.fetching}
        dataSource={props?.document?.data}
        total={props?.document?.meta?.total_count || 0}
        onDataFetch={props.getDocuments}
        onDownloadZip={downloadDocumentsInZip}
        scroll={{ x: 1200, y: "calc(100vh - 320px)" }}
        customSearchKey="search"
        displayRowClass={displayRowClass}
        onRowSelectionAction={onRowSelectionAction}
        selectedRowKeys={selectedRowKeys}
        searchPlaceholder="Search file"
        columnFilterAsString
        getCheckboxProps
        rowSelection
        downloadable
        addDocumentButton
        showSearch
        filterType={props.filterType}
        filters={props.filters}
      />
    </Spin>
  );
};

export default memo(DocumentsTable);
