import { memo } from "react";
import { Space, Tag } from "antd";
import { displayDateTime } from "utils/helpers";
import { ITimeline, TimelineTypeEnum } from "redux/components/common";
import { PmExternalTag, SparePartTag, TaskTag } from "../tags";

export interface IAssetTimelineLabelProps {
  timeline: ITimeline;
}

const TIMELINE_TYPES = {
  [TimelineTypeEnum.TASK]: <TaskTag />,
  [TimelineTypeEnum.SPARE_PART]: <SparePartTag />,
  [TimelineTypeEnum.PM_INTERNAL]: <PmExternalTag />,
  [TimelineTypeEnum.PM_EXTERNAL]: <PmExternalTag />,
};

const AssetTimelineLabel: React.FC<IAssetTimelineLabelProps> = (props) => {
  return (
    <Space direction="vertical">
      {TIMELINE_TYPES[props.timeline.type]}
      <Tag color={"#fff"} className="round-tag alectify-timeline-date-tag">
        {displayDateTime(props.timeline.dueDate || props.timeline.createdAt)}
      </Tag>
    </Space>
  );
};

export default memo(AssetTimelineLabel);
