import { Breadcrumb, Col, Row, Space, Tooltip, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/Routes.constants";
import AlectifyText from "static/texts.json";
import "./contacts.styles.scss";
import ContactsTable from "./contacts-table/ContactsTable";
import TextToLink from "components/shared/text-to-link";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { isEmpty } from "lodash";

const Contacts: React.FC = () => {
  const navigate = useNavigate();
  const { activeMasterProject } = useSelector(
    (state: IRootState) => state.common,
  );
  return (
    <>
      <Breadcrumb
        className=""
        items={[
          {
            title: "Home",
            href: `#`,
            onClick: () => navigate(`${ROUTES.PROJECT}`),
          },
          {
            title: AlectifyText.CONTACTS,
          },
        ]}
      />
      <div className="sub-project-tabs">
        <Col span={24}>
          <Row justify={"space-between"} align={"middle"}>
            <Col span={12}>
              <Space>
                <Typography.Title level={4} className="mt-10">
                  {AlectifyText.CONTACTS}
                </Typography.Title>
                <Tooltip
                  title={
                    !isEmpty(activeMasterProject) ? "" : "Please Select Site."
                  }
                >
                  <TextToLink
                    className={!isEmpty(activeMasterProject) ? "" : "disabled"}
                    onClick={() => {
                      if (!isEmpty(activeMasterProject)) {
                        navigate(
                          `${ROUTES.MASTER_PROJECT_DETAILS}/${activeMasterProject.id}?page=users`,
                        );
                      } else {
                        return;
                      }
                    }}
                    text="User Management"
                  />
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </Col>
        <ContactsTable />
      </div>
    </>
  );
};

export default Contacts;
