import { IReduxActionResponse } from "redux/interfaces";
import { IPmExternalCount } from "services/pm-internal/pm-internal.interface";
import {
  IPmExternal,
  IPmExternalInitialState,
  IPmExternalResponse,
} from "../pm-external.interface";
import pmExternalInitialState from "redux/components/pm-external/pm-external.initialState";

export const getPmExternal = (
  state: IPmExternalInitialState,
  action: IReduxActionResponse<IPmExternalResponse>,
) => {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      data: payload?.data,
      fetching: false,
      meta: payload?.meta,
      message: payload?.message,
    };
  }
  return state;
};

export const setPMExterLoader = (
  state: IPmExternalInitialState,
  action: IReduxActionResponse<boolean>,
) => {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      fetching: payload,
    };
  }
  return state;
};

export const setPmExternalCounts = (
  state: IPmExternalInitialState,
  action: IReduxActionResponse<IPmExternalCount>,
): IPmExternalInitialState => {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      skippedCounts: payload?.skipped || 0,
      completedCounts: payload?.completed || 0,
      waitingForReviewCounts: payload?.waitingForReview || 0,
      pendingCounts:
        (payload?.pending || 0) +
        (payload?.waitingForReview || 0) +
        (payload?.denied || 0),
      deniedCounts: payload?.denied || 0,
    };
  }
  return state;
};

export const setTodayPmExternal = (
  state: IPmExternalInitialState,
  action: IReduxActionResponse<IPmExternal[]>,
): IPmExternalInitialState => {
  const { payload } = action;
  return {
    ...state,
    todayPmExternal: payload || [],
  };
};
export const resetPmExternalState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return {
    ...pmExternalInitialState,
  };
};

export const setWorkOrderCardSwitcher = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  const { payload } = action;
  return {
    ...state,
    activeWorkOrderCardSwitcher: payload,
  };
};
