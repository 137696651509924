import { IReduxActionResponse } from "redux/interfaces";
import { tableFilterInitialState } from "../TableFilter.initialState";

export const resetTableFilterState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return { ...tableFilterInitialState };
};

export const setTableFilters = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  const { payload } = action;
  return {
    tableFilters: {
      ...state.tableFilters,
      filterType: payload?.filterType ?? state.tableFilters.filterType,
      createdAt:
        payload?.createdAt !== undefined
          ? payload.createdAt
          : state.tableFilters.createdAt,
      dueDate:
        payload?.dueDate !== undefined
          ? payload.dueDate
          : state.tableFilters.dueDate,
      statusShifterButtons:
        payload?.statusShifterButtons ??
        state.tableFilters.statusShifterButtons,
      status:
        payload?.status !== undefined
          ? payload.status
          : state.tableFilters.status,

      taskCategory:
        payload?.taskCategory !== undefined
          ? payload.taskCategory
          : state.tableFilters.taskCategory,

      orderTypeTab:
        payload?.orderTypeTab !== undefined
          ? payload.orderTypeTab
          : state.tableFilters.orderTypeTab,
    },
  };
};
