import React, { ReactNode } from "react";
import { isEmpty, truncate } from "lodash";
import { ITask } from "redux/components/tasks";
import { Avatar, Badge, Dropdown, Space, Tooltip, message } from "antd";
import {
  displayDateTime,
  enumToTile,
  getFullUserName,
  isExternalUser,
  truncateText,
} from "utils/helpers";
import {
  TaskStatusEnum,
  CommentReferenceEnum,
  TaskPriorityEnum,
  PM_STATUS,
  PME_ACTION_REQUIRED_ENUM,
} from "enums";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import {
  PaperClipOutlined,
  MessageOutlined,
  EllipsisOutlined,
  CloseOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { actionItems } from "../TaskTable.rules";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import TextToLink from "components/shared/text-to-link";
import AvatarGroup from "components/shared/avatar-group";
// import AssetDetail from "components/shared/asset-detail";
import AlectifyText from "static/texts.json";
import AlectifyDrawer from "components/drawer";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
// import PackageDetailAssets from "components/shared/package-detail-assets";
import useMyWorkOrdersColumns from "pages/my-work-orders/effects/useMyWorkOrdersColumns";
import Comments from "components/shared/comments";
import {
  ApprovedTag,
  ApproverTag,
  AssetPackageTag,
  AssetTag,
  AssigneeTag,
  CompletedTag,
  GenericTag,
  ScheduledTag,
  SkippedTag,
  WaitingForApprovalTag,
  WaitingForReviewTag,
} from "components/shared/tags";
import DangerTag from "components/shared/tags/DangerTag";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";

import "assets/scss/global.scss"; //@Todo: need to add this file in to single style files, so it can be imported automatically
import DelayedTag from "components/shared/tags/DelayedTag";
import OnTimeTag from "components/shared/tags/OnTimeTag";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { useNavigate } from "react-router-dom";
import ClosedTag from "components/shared/tags/ClosedTag";
import { CalendarDateIcon, UserIcon, WarningIcon } from "components/icons";
import { ITasksTableProps } from "../TasksTable.interface";
import { IProcedure } from "services/procedures/procedures.interface";
import StampCheckIcon from "components/icons/StampCheckIcon";
import { DONE_TASK_GREEN_COLOR } from "components/shared/phone-number/constants";
import { IParams, IPmAssignees } from "redux/interfaces";
import { IPmExternal, PM_TYPES } from "redux/components/pm-external";
import PreventiveDocumentsAttachment from "components/shared/preventive-documents/PreventiveDocumentsAttachment";
import ProcedureDetail from "components/shared/procedure-detail";
import PmDetail from "components/shared/pm-detail";
import { undoTaskStatus } from "services/tasks/tasks.service";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ConfirmationModal from "components/shared/confirmation/Confirmation";
import { MESSAGES } from "constants/messages";
import ReopenedIcon from "components/icons/ReopenedIcon";
import "../TasksTable.scss";
// import AlectifyChip from "components/shared/chips/AlectifyChip";
import ViewImage from "components/shared/view-image/ViewImage";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import { ROUTES } from "routes/Routes.constants";
import { actions as NotificationActions } from "redux/components/notifications";
import useSimpleColumns from "./useSimpleColumns";
// import RecurringIcon from "components/icons/RecurringIcon";
import AlectifyAiSummaryButton from "components/shared/ai-summary-button/AlectifyAISummaryButton";
// import MultiAssetTag from "components/shared/tags/MultiAssetTag";
import AlectifyTable from "components/shared/table";
// import NoAssetTag from "components/shared/tags/NoAssetTag";
import AlectifyButton from "components/shared/button";
import { statusMap } from "utils/constants";
// import SwitchSubProjectDrawer from "pages/sub-project/swtich-sub-project-drawer";
// import { IMasterProject } from "redux/components/master-project";
// import { setActiveMasterProject } from "redux/components/common/sources";
import AlectifyCarousel from "components/carousel/AlectifyCarousel";
import {
  getAttachmentsMedia,
  // getPmInternalAttachments,
} from "redux/components/pm-internal/sources";
import { renderWorkOrderRepeats } from "pages/my-work-orders/details/pm/WorkOrder.helper";
import {
  getPMEActionRequired,
  isDelayed,
} from "pages/work-order/WorkOrderCreateEdit.constants";
import InProgressTag from "components/shared/tags/InProgressTag";
import RecurringIcon from "components/icons/RecurringIcon";
import { setSimpleTableDetail } from "redux/components/common/sources";

export interface ITaskTableColumnHookProps extends ITasksTableProps {
  onEdit: (_: any, editing: boolean, editType: string, record: ITask) => void;
  filter: string[];
  optionsPersisted?: IPagination | null;
  showSelection?: boolean;
  fetchTasks: () => void;
  updateStatus: (pmId: IPmExternal | ITask, status: string) => void;
  openSubmitForReviewModal: (task: ITask) => void;
  removeTask: (id: string) => void;
  openSpareParts?: (task: IPmExternal | ITask) => void;
  onSelectWorkOrder?: (task: ITask) => void;
}

export const PM_STATUS_COMPONENTS = {
  [PM_STATUS.COMPLETED]: <ClosedTag />,
  [PM_STATUS.PENDING]: <ScheduledTag />,
  [PM_STATUS.SKIPPED]: <SkippedTag />,
  [PM_STATUS.WAITING_FOR_REVIEW]: <WaitingForReviewTag />,
  [PM_STATUS.INPROGRESS]: <InProgressTag />,
  [PM_STATUS.DENIED]: <DangerTag text={AlectifyText.DENIED} />,
};
export const TASK_STATUS_COMPONENTS = {
  [TaskStatusEnum.PENDING]: <ScheduledTag />,
  [TaskStatusEnum.APPROVED]: <ApprovedTag />,
  [TaskStatusEnum.SCHEDULED]: <ScheduledTag />,
  [TaskStatusEnum.COMPLETED]: <ClosedTag />,
  [TaskStatusEnum.REVIEW_DECLINED]: <WaitingForReviewTag />,
  [TaskStatusEnum.WAITING_FOR_REVIEW]: <WaitingForReviewTag />,
  [TaskStatusEnum.WAITING_FOR_APPROVAL]: <WaitingForApprovalTag />,
};
export const taskStatusStepIcon = (
  currentStatus: string,
  statusToMove: string,
): ReactNode => {
  if (
    currentStatus === PM_STATUS.PENDING &&
    statusToMove === PM_STATUS.WAITING_FOR_REVIEW
  ) {
    return AlectifyText.SUBMIT_REVIEW;
    /*  <Row justify={"space-between"} align={"middle"}>
        <Col span={20}>{AlectifyText.SUBMIT_REVIEW}</Col>
        <Col span={4} className="text-align-right">
          <ForwardOutlined style={{ color: "#0954f1", fontSize: 15 }} />
        </Col>
      </Row> */
  } else if (
    currentStatus === PM_STATUS.INPROGRESS &&
    statusToMove === PM_STATUS.WAITING_FOR_REVIEW
  ) {
    return AlectifyText.SUBMIT_REVIEW;
    // <Row justify={"space-between"} align={"middle"}>
    //   <Col span={20}>{AlectifyText.SUBMIT_REVIEW}</Col>
    //   <Col span={4} className="text-align-right">
    //     <ForwardOutlined style={{ color: "#0954f1", fontSize: 15 }} />
    //   </Col>
    // </Row>
  } else if (
    currentStatus === PM_STATUS.PENDING &&
    statusToMove === PM_STATUS.INPROGRESS
  ) {
    return AlectifyText.IN_PROGRESS;
    // <Row justify={"space-between"} align={"middle"}>
    //   <Col span={12}>{AlectifyText.IN_PROGRESS}</Col>
    //   <Col span={12} className="text-align-right">
    //     <ForwardOutlined style={{ color: "#0954f1", fontSize: 15 }} />
    //   </Col>
    // </Row>
  } else if (
    /* else if (
    currentStatus === PM_STATUS.INPROGRESS &&
    statusToMove === PM_STATUS.PENDING
  ) {
    return AlectifyText.SCHEDULED;
    <Row justify={"space-between"} align={"middle"}>
      <Col span={12}>{AlectifyText.SCHEDULED}</Col>
      <Col span={12} className="text-align-right">
        <BackwardOutlined style={{ color: "red", fontSize: 15 }} />
      </Col>
    </Row>
  }  */
    /* else if (
    currentStatus === PM_STATUS.WAITING_FOR_REVIEW &&
    statusToMove === PM_STATUS.INPROGRESS
  ) {
    return (
      <Row justify={"space-between"} align={"middle"}>
        <Col span={12}>{AlectifyText.IN_PROGRESS}</Col>
        <Col span={12} className="text-align-right">
          <BackwardOutlined style={{ color: "red", fontSize: 15 }} />
        </Col>
      </Row>
    );
  }  */
    currentStatus === PM_STATUS.WAITING_FOR_REVIEW &&
    statusToMove === PM_STATUS.COMPLETED
  ) {
    return AlectifyText.REVIEW_COMPLETE;
    // <Row justify={"space-between"} align={"middle"}>
    //   <Col span={20}>{AlectifyText.REVIEW_COMPLETE}</Col>
    //   <Col span={4} className="text-align-right">
    //     <ForwardOutlined style={{ color: "#0954f1", fontSize: 15 }} />
    //   </Col>
    // </Row>
  }
  return <></>;
};
export const TASK_PROIRITY_COMPONENTS = {
  [TaskPriorityEnum.NORMAL]: <CompletedTag text={AlectifyText.NORMAL} />,
  [TaskPriorityEnum.CRITICAL]: <DangerTag text={AlectifyText.CRITICAL} />,
};

export const ACTION_REQUIRED_COMPONENTS = (
  action: PME_ACTION_REQUIRED_ENUM | null,
) => {
  switch (action) {
    case PME_ACTION_REQUIRED_ENUM.ASSIGNEE:
      return <AssigneeTag text={action} />;
    case PME_ACTION_REQUIRED_ENUM.APPROVERS:
      return <ApproverTag />;
    default:
      return <></>;
  }
};

export const getTaskStatusIcon = (procedure: IProcedure): JSX.Element => {
  const isAllChecked =
    procedure.procedureStepCheckedTotalCount ===
    procedure.procedureStepTotalCount;
  return isAllChecked ? (
    <StampCheckIcon fill={DONE_TASK_GREEN_COLOR} />
  ) : (
    <StampCheckIcon />
  );
};

const useTasksColumn = (props: ITaskTableColumnHookProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTaskType = props.type === PM_TYPES.TASK;

  const onCell = (record: any, rowIndex: number) => ({
    style: {
      cursor: "pointer",
    },

    onClick: () => {
      goToPmDetail(record);
    },
  });

  // const onMasterProjectClick = (masterProject: IMasterProject) => {
  //   if (!isExternalUser(user)) {
  //     dispatch(setActiveMasterProject(masterProject));
  //     navigate(`${ROUTES.MASTER_PROJECT_DETAILS}/${masterProject.id}`);
  //   }
  //   DrawerServiceInstance.close(AlectifyDrawer, {
  //     name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
  //   });
  // };

  const myItemsColumns = useMyWorkOrdersColumns({
    isTaskType: isTaskType,
    onCell: onCell,
  });
  const { pmExternal } = useSelector((state: IRootState) => state);
  const { user } = useSelector(({ auth }: IRootState) => auth);

  const simpleItemsColumn = useSimpleColumns(props);

  const currentUser = useSelector((state: IRootState) => state.auth.user);
  const { showMyWorkOrders } = useSelector((state: IRootState) => state.common);
  const { projectTeamMembers } = useSelector(({ users }: IRootState) => users);

  const goToPmDetail = (task: ITask) => {
    dispatch(
      setSimpleTableDetail({
        pageIsOnMaintenanceDetail: true,
      }),
    );

    const pmId = task.id;
    const masterProjectId = task?.project?.id;
    const subProjectId = task?.subProject?.id;
    const url = `${ROUTES.MY_ITEMS}/pm/${masterProjectId}/${subProjectId}/${pmId}?pmType=${task.pmType}`;
    dispatch(NotificationActions.toggleNotificationDropdown(false));
    navigate(url);
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: [DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER],
    });
  };

  // const TASK_CATEGORY_MAP = {
  //   PREVENTIVE_MAINTENANCE: "PM",
  //   CORRECTIVE_MAINTENANCE: "CM",
  //   TASK: "Task",
  // };
  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.CREATED,
      key: "created",
      dataIndex: "createdAt",
      visible: false,
      // visible: isTaskType ? false : !props.myItemsColumns,---->{dependency checking}
      ellipsis: true,
      width: 110,
      onCell,
      render: (target: any, record: ITask) => (
        <div className="d-flex justify-content-space-between">
          <div className="status-column">
            {record?.priority === TaskPriorityEnum.CRITICAL ? (
              <div className="critical-text">{AlectifyText.CRITICAL}</div>
            ) : null}
          </div>
          <Space direction="vertical" size={15}>
            <div className="creator-container">
              <CalendarDateIcon />
              <span className="creator-name-date">
                {`${displayDateTime(
                  record?.createdAt || record?.createdAt,
                  true,
                  false,
                )}`}
              </span>
            </div>
            <div className="creator-container">
              {isEmpty(record?.createdBy?.image_url) ? (
                <UserIcon />
              ) : (
                <Avatar
                  src={record?.createdBy?.image_url}
                  size="small"
                  className="cursor-pointer"
                  onClick={() =>
                    ModalServiceInstance.open(AlectifyModal, {
                      name: "alectify-task-image-viewer",
                      title: "Preview",
                      footer: null,
                      onCancel: () => {
                        ModalServiceInstance.close(AlectifyModal, {
                          name: "alectify-task-image-viewer",
                        });
                      },
                      children: (
                        <ViewImage
                          imageUrl={
                            record?.createdBy?.image_url || NO_IMAGE_AVATAR
                          }
                          isDownloadable={
                            !isEmpty(record?.createdBy?.image_url)
                          }
                        />
                      ),
                    })
                  }
                />
              )}
              <span className="creator-name-date">
                {`${
                  record?.createdAt && !isEmpty(record?.createdAt)
                    ? getFullUserName(record?.createdBy as any)
                    : record?.createdBy && !isEmpty(record?.createdBy)
                    ? getFullUserName(record?.createdBy)
                    : "-"
                }`}
              </span>
              {/* {record?.taskCategory && (
                <span>
                  {record?.taskCategory && (
                    <Tooltip title={`${enumToTile(record.taskCategory)}`}>
                      <AlectifyChip
                        text={
                          (TASK_CATEGORY_MAP as any)[record.taskCategory as any]
                        }
                        textColor={
                          record?.taskCategory ===
                          MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                            ? "red"
                            : "0954f1"
                        }
                        backgroundColor={
                          record?.taskCategory ===
                          MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                            ? "#fde8e8"
                            : "e8f1fd"
                        }
                        borderColor={
                          record?.taskCategory ===
                          MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                            ? "#ff0000"
                            : "e8f1fd"
                        }
                      />
                    </Tooltip>
                  )}
                </span>
              )} */}
            </div>
          </Space>
        </div>
      ),
    },

    {
      title: AlectifyText.CREATED,
      key: "created",
      dataIndex: "createdAt",
      visible: false,
      // visible: !isTaskType ? false : !props.myItemsColumns ---> {dependency checking},
      ellipsis: true,
      width: 110,
      render: (target: any, record: ITask) => (
        <>
          {/* <div className="status-column">
            {record?.priority === TaskPriorityEnum.CRITICAL ? (
              <div className="critical-text">{AlectifyText.CRITICAL}</div>
            ) : null}
          </div> */}
          <Space direction="vertical" size={15}>
            <div className="creator-container">
              <CalendarDateIcon />
              <span className="creator-name-date">
                {`${displayDateTime(
                  record?.createdAt || record?.createdAt,
                  true,
                  false,
                )}`}
              </span>
            </div>
            <div className="creator-container">
              {isEmpty(record?.createdBy?.image_url) ? (
                <UserIcon />
              ) : (
                <Avatar src={record?.createdBy?.image_url} size="small" />
              )}
              {/* <span className="creator-name-date">
                {`${
                  record?.createdAt && !isEmpty(record?.createdAt)
                    ? getFullUserName(record?.createdBy as any)
                    : record?.createdBy && !isEmpty(record?.createdBy)
                    ? getFullUserName(record?.createdBy)
                    : "-"
                }`}
              </span> */}
            </div>
          </Space>
        </>
      ),
    },
    {
      title: AlectifyText.TITLE,
      dataIndex: "workTitle",
      width: 150,
      visible: true,
      onCell,
      render: (value: string, task: ITask) => {
        const handleFetchAttachments = async () => {
          try {
            const params = {
              page: 1,
              limit: 5,
              signedUrls: true,
              fileType: "media",
            };
            await dispatch(getAttachmentsMedia(task.id, params as IParams));
          } catch (err) {
            console.error(err);
          }
        };

        const openProcedureCheckListDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 700,
            title: "Procedure",
            name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
            closable: true,
            closeIcon: <CloseOutlined />,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
              });
              props.fetchTasks();
            },
            showFooter: true,
            destroyOnClose: true,
            readOnly: true,
            cancelText: AlectifyText.CLOSE,
            children: (
              <ProcedureDetail
                procedure={task.procedure as any}
                taskId={task.id}
                isDisabled={
                  task.status === PM_STATUS.COMPLETED ||
                  task.status === PM_STATUS.SKIPPED
                }
              />
            ),
          });
        };

        return (
          <Space direction="horizontal" size={15}>
            <Avatar
              src={task.imageUrl || NO_IMAGE_AVATAR}
              className="cursor-pointer alectify-avatar-clickable-border "
              size={50}
              onClick={(e: any) => {
                handleFetchAttachments();
                e.stopPropagation();
                ModalServiceInstance.open(AlectifyModal, {
                  name: "alectify-image-carousel",
                  title: "",
                  footer: null,
                  width: 800,
                  className: "carousel-modal-body",
                  onCancel: () => {
                    ModalServiceInstance.close(AlectifyModal, {
                      name: "alectify-image-carousel",
                    });
                  },
                  children: (
                    <AlectifyCarousel
                      videoWidth={"50rem"}
                      videoHeight={"auto"}
                      details={task}
                      fetchMoreAttachments={async (page?: number) => {
                        try {
                          const params = {
                            page: page || 1,
                            limit: 5,
                            signedUrls: true,
                            fileType: "media",
                          };
                          await dispatch(
                            getAttachmentsMedia(task.id, params as IParams),
                          );
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      thumbnailLimit={5}
                      recordID={task.id}
                    />
                  ),
                });
              }}
            />
            <Space direction="vertical">
              <div className="d-flex align-items-center">
                <Tooltip title={value}>
                  <TextToLink
                    className="text-to-link-options"
                    text={truncate(value, { length: 30, omission: "..." })}
                    onClick={(e) => {
                      e.stopPropagation();
                      DrawerServiceInstance.open(AlectifyDrawer, {
                        width: 480,
                        title: truncateText(task.workTitle, 40),
                        name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                        closable: true,
                        className: "bg-grey",
                        closeIcon: <CloseOutlined />,
                        onClose: () =>
                          DrawerServiceInstance.close(AlectifyDrawer, {
                            name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                          }),
                        children: (
                          <PmDetail
                            pmExternalRecord={task as any}
                            pmId={task.id}
                            pmType={
                              props.type === "ALL" ? task.pmType : props.type
                            }
                            isMasterPM={props.isMasterPM}
                          />
                        ),
                      });
                    }}
                  />
                </Tooltip>
                <span className="ml-5 mt-3">
                  {task.isReopened && (
                    <Tooltip title={AlectifyText.REOPENED}>
                      <ReopenedIcon />
                    </Tooltip>
                  )}
                </span>
              </div>
              {(!isEmpty(task.procedure) && (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    openProcedureCheckListDrawer();
                  }}
                  className="cursor-pointer d-flex align-items-center"
                >
                  <Tooltip title={task.workId}>
                    <span className="mr-5">
                      {truncate(task.workId, { length: 40, omission: "..." })}
                    </span>
                  </Tooltip>

                  <Tooltip
                    title={`${AlectifyText.PROCEDURE_CHECKLIST} ${task.procedure?.procedureStepCheckedTotalCount}/${task.procedure?.procedureStepTotalCount}`}
                  >
                    {!isEmpty(task.procedure) &&
                      getTaskStatusIcon(task.procedure)}
                  </Tooltip>
                </span>
              )) || (
                <Tooltip title={task.workId}>
                  <span>
                    {truncate(task.workId, {
                      length: 40,
                      omission: "...",
                    })}
                  </span>
                </Tooltip>
              )}
            </Space>
          </Space>
        );
      },
    },

    {
      title: AlectifyText.ASSETS_SITE,
      key: "asset_type",
      dataIndex: "asset_type",
      width: 120,
      visible: true,
      searchable: true,
      ellipsis: true,
      onCell,
      render: (_, record: ITask) => {
        const masterProject = record?.project;
        const assetCount = record.assets.length || 0;
        const areaCount = record.areas.length || 0;
        const total = assetCount + areaCount || 0;
        const displayText =
          total > 1 ? (
            <strong>{`Multiple Assets [${total}]`} </strong>
          ) : (
            (record.assets[0] as any)?.asset?.name ||
            (record.areas[0] as any)?.area?.name ||
            "No Asset"
          );
        const isGeneric = record?.isGeneric;
        return (
          <Space direction="vertical" size={15} className="width-100">
            <Tooltip
              title={
                (!isEmpty(record.assets) || !isEmpty(record.areas)) && "Assets"
              }
            >
              {(!isGeneric && (
                <TextToLink
                  className="text-to-link-options"
                  text={displayText}
                  underline={!isGeneric}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isGeneric) {
                      const hasMultipleAssets = record.assets.length > 1;
                      const hasMultipleAreas = record.areas.length > 1;

                      if (hasMultipleAssets || hasMultipleAreas) {
                        // Open the modal with the list of assets and areas
                        ModalServiceInstance.open(AlectifyModal, {
                          name: MODAL_NAMES.ASSET_NAME_MODAL,
                          title: `Asset list of (${record.workTitle})`,
                          width: 800,
                          footer: null,
                          children: (
                            <AlectifyTable
                              onDataFetch={() => {}}
                              dataSource={[
                                ...record.assets.map((asset: any) => {
                                  return { ...asset.asset, type: "Tag" };
                                }),
                                ...record.areas.map((ar: any) => {
                                  return { ...ar.area, type: "PackageRoom" };
                                }),
                              ]}
                              total={[...record.assets, ...record.areas].length}
                              showPagination={false}
                              columns={[
                                {
                                  dataIndex: "name",
                                  title: "Name",
                                  width: 180,
                                  render: (value, assetRecord) => {
                                    const goToAssetDetails = () => {
                                      let url: string;
                                      if (assetRecord.type === "PackageRoom") {
                                        url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset-package/${assetRecord.id}?page=timeline`;
                                      } else {
                                        url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset/${assetRecord.id}?page=timeline`;
                                      }
                                      window.open(url, "_blank"); // Open in a new tab
                                    };
                                    return (
                                      <TextToLink
                                        text={
                                          <Space>
                                            <Tooltip title={value || "NA"}>
                                              <span>
                                                {truncateText(value, 29)}
                                              </span>
                                            </Tooltip>
                                            <ExportOutlined />
                                          </Space>
                                        }
                                        onClick={goToAssetDetails}
                                      />
                                    );
                                  },
                                },

                                {
                                  title: AlectifyText.ASSET_TYPE,
                                  key: "asset_type",
                                  width: 140,
                                  render: (value, assetRecord) => {
                                    return (
                                      <>
                                        {assetRecord.type === "Tag" ? (
                                          <AssetTag />
                                        ) : assetRecord.type ===
                                          "PackageRoom" ? (
                                          <AssetPackageTag />
                                        ) : (
                                          <GenericTag />
                                        )}
                                      </>
                                    );
                                  },
                                },
                                {
                                  dataIndex: "location",
                                  title: AlectifyText.LOCATION,
                                  width: 160,
                                  ellipsis: true,
                                  render: (location, assetRecord) => {
                                    return (
                                      <Tooltip title={location || "NA"}>
                                        {truncateText(location || "-", 30)}
                                      </Tooltip>
                                    );
                                  },
                                },
                                {
                                  dataIndex: "description",
                                  title: "description",
                                  width: 200,
                                  ellipsis: true,
                                  render: (description, assetRecord) => {
                                    return (
                                      <Tooltip title={description || "NA"}>
                                        {truncateText(description || "-", 37)}
                                      </Tooltip>
                                    );
                                  },
                                },
                              ]}
                            />
                          ),
                          onCancel: () =>
                            ModalServiceInstance.close(AlectifyModal, {
                              name: MODAL_NAMES.ASSET_NAME_MODAL,
                            }),
                        });
                      } else {
                        // Redirect directly to asset/area detail page
                        const singleAsset: any =
                          record.assets.length === 1 ? record.assets[0] : null;
                        const singleArea: any =
                          record.areas.length === 1 ? record.areas[0] : null;

                        if (singleAsset) {
                          const url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset/${singleAsset.asset.id}?page=timeline`;
                          window.open(url, "_blank");
                        } else if (singleArea) {
                          const url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset-package/${singleArea.area.id}?page=timeline`;
                          window.open(url, "_blank");
                        }
                      }
                    }
                  }}
                />
              )) ||
                displayText}
            </Tooltip>
            {/* {!isEmpty(record.assets) || !isEmpty(record.areas) ? (
              record.areas.length > 1 || record.assets.length > 1 ? (
                <MultiAssetTag />
              ) : record.areas.length === 1 ? (
                <AssetPackageTag />
              ) : record.assets.length === 1 ? (
                <AssetTag />
              ) : (
                <NoAssetTag />
              )
            ) : (
              <NoAssetTag />
            )} */}
            {masterProject?.name ? (
              <Tooltip placement="topLeft" title={masterProject?.name}>
                {!isExternalUser(user) && (
                  <div
                    className="text-ellipsis width-asset-type-text-control"
                    // onClick={(e) => {
                    //   e.stopPropagation();
                    //   DrawerServiceInstance.open(AlectifyDrawer, {
                    //     width: 420,
                    //     name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
                    //     title: masterProject?.name,
                    //     closable: true,
                    //     closeIcon: <CloseOutlined />,
                    //     onClose: () =>
                    //       DrawerServiceInstance.close(AlectifyDrawer, {
                    //         name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
                    //       }),
                    //     children: (
                    //       <SwitchSubProjectDrawer
                    //         masterProjectId={masterProject.id}
                    //         navigate={navigate}
                    //       />
                    //     ),
                    //     customFooter: (
                    //       <AlectifyButton
                    //         text={AlectifyText.VISIT_SITE}
                    //         className="ant-btn-primary alectify-btn-block "
                    //         block
                    //         onClick={() => onMasterProjectClick(masterProject)}
                    //       />
                    //     ),
                    //   });
                    // }}
                  >
                    <span>{masterProject?.name}</span>
                  </div>
                )}
              </Tooltip>
            ) : (
              "-"
            )}
          </Space>
        );
      },
    },
    {
      title: AlectifyText.WORK_ORDER_TYPE,
      key: "taskCategory",
      dataIndex: "taskCategory",
      visible: true,
      ellipsis: true,
      width: 160,
      onCell,
      render: (target: any, record: ITask) => (
        <Space direction="vertical" size={15}>
          <div className="d-flex">
            {`${
              record.pmType === "TASK"
                ? "Task"
                : (record.taskCategory && enumToTile(record.taskCategory)) ||
                  "-"
            }`}

            {record.isRecurring && (
              <Tooltip title={renderWorkOrderRepeats(record)}>
                <span className="ml-5">
                  <RecurringIcon />
                </span>
              </Tooltip>
            )}
          </div>

          {record?.priority === TaskPriorityEnum.CRITICAL ? (
            <DangerTag text={AlectifyText.CRITICAL} />
          ) : (
            <CompletedTag text="Normal" />
          )}
        </Space>
      ),
    },
    {
      title: AlectifyText.STATUS,
      dataIndex: "dueDate",
      key: "dueDate",
      width: 110,
      visible: true,
      onCell,
      render: (value: Date, task: ITask) => {
        return (
          <Space direction="vertical" size={15}>
            {`Due: ${displayDateTime(value)}`}
            <Space>{PM_STATUS_COMPONENTS[task.status]}</Space>
            {/* {`Completed at: ${displayDateTime(pmExternal.completedAt)}`} */}
          </Space>
        );
      },
    },
    {
      title: AlectifyText.COMPLETION_DATE,
      dataIndex: "completedAt",
      key: "completedAt",
      width: 100,
      onCell,
      visible: props.filter.includes(PM_STATUS.COMPLETED),
      render: (value: Date, task: ITask) => {
        return (
          <Space direction="vertical" size={15}>
            {`On: ${displayDateTime(value)}`}
            {isDelayed(value, task.dueDate) ? <DelayedTag /> : <OnTimeTag />}
          </Space>
        );
      },
    },
    {
      title: AlectifyText.ACTION_REQUIRED_BY,
      dataIndex: "assignees",
      key: "assignees",
      width: 90,
      onCell,
      filters: projectTeamMembers?.data.map((user) => ({
        text: getFullUserName(user),
        value: user.id,
      })),
      visible:
        props.filter.includes(PM_STATUS.PENDING) ||
        props.filter.includes(PM_STATUS.WAITING_FOR_REVIEW),
      render: (assignees: IPmAssignees[], record: ITask) => {
        const actionRquired = getPMEActionRequired(record);
        const ACTION_REQUIRED = ACTION_REQUIRED_COMPONENTS(actionRquired);
        if (!isEmpty(assignees) || !isEmpty(record.approvers)) {
          return (
            <Space direction="vertical" size={7} className="mb-3">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <AvatarGroup
                  maxCount={2}
                  showImage
                  users={
                    actionRquired === PME_ACTION_REQUIRED_ENUM.ASSIGNEE
                      ? assignees.map(({ user }) => user)
                      : record.approvers?.map(({ user }) => user)
                  }
                />
              </div>
              {ACTION_REQUIRED}
            </Space>
          );
        }
        return <></>;
      },
    },
    {
      title: AlectifyText.MESSAGES,
      width: 70,
      dataIndex: "name",
      visible: true,
      searchable: true,
      render: (_, record) => {
        const subProject = record.subProject || null;

        const openAttachmentsDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 860,
            title: AlectifyText.ATTACHMENTS,
            name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
            closable: true,
            closeIcon: <CloseOutlined />,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
              });
              props.fetchTasks();
            },
            children: <PreventiveDocumentsAttachment details={record} />,
          });
        };

        const openCommentsDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 600,
            title: AlectifyText.MESSAGING_CENTER,
            name: DRAWER_CONSTANTS.DRAWER_COMMENTS,
            closable: true,
            closeIcon: <CloseOutlined />,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.DRAWER_COMMENTS,
              });
              props.fetchTasks();
            },
            children: (
              <Comments
                details={record && record}
                referenceId={record.id}
                subProjectId={subProject?.id}
                reference_type={CommentReferenceEnum.PM_EXTERNAL}
              />
            ),
            className: "alectify-drawer-task-comments",
          });
        };

        return (
          <Space
            size={15}
            direction="vertical"
            className="alectify-task-table-collaboration-column"
          >
            <Tooltip placement="topLeft" title={AlectifyText.MESSAGING_CENTER}>
              <Badge
                offset={[2, 0]}
                overflowCount={9}
                className="cursor-pointer"
                size="small"
              >
                <Space direction="horizontal">
                  <MessageOutlined
                    className="icon-font-size-18"
                    onClick={openCommentsDrawer}
                    style={{
                      color: "#0954f1",
                    }}
                  />
                  <span
                    className="alectify-task-table-collaboration-count-text"
                    onClick={openCommentsDrawer}
                  >
                    {record?.comments}
                  </span>
                </Space>
              </Badge>
            </Tooltip>

            <Tooltip placement="topLeft" title={AlectifyText.ATTACHMENTS}>
              <Badge
                offset={[2, 0]}
                overflowCount={9}
                className="cursor-pointer"
                size="small"
              >
                <Space direction="horizontal">
                  <PaperClipOutlined
                    className="icon-font-size-18"
                    onClick={openAttachmentsDrawer}
                    style={{
                      color: "#0954f1",
                    }}
                  />
                  <span
                    className="alectify-task-table-collaboration-count-text"
                    onClick={openAttachmentsDrawer}
                  >
                    {record?.documents}
                  </span>
                </Space>
              </Badge>
            </Tooltip>
          </Space>
        );
      },
    },
    {
      title: AlectifyText.AI_SUMMARY,
      dataIndex: "summary",
      key: "summary",
      width: 100,
      visible: true,
      render: (_, record: ITask) => {
        return (
          <AlectifyAiSummaryButton
            content={record.summary}
            name={record.workTitle}
            key={record.id}
          />
        );
      },
    },
    {
      width: 70,
      title: AlectifyText.ACTIONS,
      align: "center",
      fixed: "right",
      dataIndex: "",
      visible: !props.isMasterPM,
      render: (_, task: ITask) => {
        if (!props.showSelection) {
          const openAttachmentsDrawer = () => {
            DrawerServiceInstance.open(AlectifyDrawer, {
              width: 860,
              title: "Multiple asset",
              closable: true,
              closeIcon: <CloseOutlined />,
              onClose: () => {
                DrawerServiceInstance.close(AlectifyDrawer);
                props.fetchTasks();
              },
              children: <PreventiveDocumentsAttachment details={task} />,
            });
          };
          const openProcedureCheckListDrawer = () => {
            DrawerServiceInstance.open(AlectifyDrawer, {
              width: 680,
              title: "Procedure",
              name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
              closable: true,
              closeIcon: <CloseOutlined />,
              onClose: () => {
                DrawerServiceInstance.close(AlectifyDrawer, {
                  name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
                });
                props.fetchTasks();
              },
              showFooter: true,
              destroyOnClose: true,
              readOnly: true,
              cancelText: AlectifyText.CLOSE,
              children: (
                <ProcedureDetail
                  procedure={task.procedure as any}
                  taskId={task.id}
                  isDisabled={
                    task.status === PM_STATUS.COMPLETED ||
                    task.status === PM_STATUS.SKIPPED
                  }
                />
              ),
            });
          };

          const handleUndoConfirm = async () => {
            try {
              ModalServiceInstance.close(AlectifyModal, {
                name: MODAL_NAMES.UNDO_WARNING_MODAL,
              });
              const resp = await undoTaskStatus(task.id);
              if (resp?.status) {
                message.success(
                  `${task?.workTitle} has changed the state from ${
                    statusMap[task?.status] || task?.status
                  } to ${statusMap[resp.data?.status] || resp.data?.status}`,
                );
                props.fetchTasks();
              }
            } catch (error) {
              message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
            }
          };
          const openWarningmodal = () => {
            ModalServiceInstance.open(AlectifyModal, {
              name: MODAL_NAMES.UNDO_WARNING_MODAL,
              title: AlectifyText.CONFIRMATION,
              footer: null,
              onCancel: () => {
                ModalServiceInstance.close(AlectifyModal, {
                  name: MODAL_NAMES.UNDO_WARNING_MODAL,
                });
              },
              children: (
                <ConfirmationModal
                  icon={WarningIcon}
                  message={MESSAGES.PM_EXTERNAL.REOPEN_WARNING}
                  note={MESSAGES.PM_EXTERNAL.REOPEN_NOTE}
                  onConfirm={handleUndoConfirm}
                  okText="Re open"
                  cancelText="Cancel"
                  onCancel={() =>
                    ModalServiceInstance.close(AlectifyModal, {
                      name: MODAL_NAMES.UNDO_WARNING_MODAL,
                    })
                  }
                />
              ),
            });
          };

          const dropdownItems = actionItems({
            currentUser,
            showMyWorkOrders,
            task,
            projectId: props.masterProjectId || "",
            subProjectId: props.subProjectId || "",
            navigate,
            myItemsColumns: props.myItemsColumns,
            onEdit: props.onEdit,
            updateStatus: props.updateStatus,
            openSubmitForReviewModal: props.openSubmitForReviewModal,
            onUpload: openAttachmentsDrawer,
            openProcedureCheckListDrawer,
            removeTask: props.removeTask,
            openSpareParts: props.openSpareParts,
            openWarningmodal: openWarningmodal,
            taskStatusStepIcon: taskStatusStepIcon,
            showDetailPageLink: true,
            goToPmDetail,
          });

          const renderDropdown: any =
            dropdownItems && dropdownItems.length > 0 ? dropdownItems : [];

          return (
            <>
              <Dropdown
                menu={{
                  items: renderDropdown,
                }}
                trigger={["click"]}
              >
                <EllipsisOutlined
                  rotate={90}
                  className="alectify-action-icon"
                />
              </Dropdown>
            </>
          );
        } else {
          <AlectifyButton
            type="primary"
            className="alectify-primary-btn"
            text="Select"
          />;
        }
      },
    },
  ];
  return React.useMemo(() => {
    if (pmExternal.activeWorkOrderCardSwitcher) {
      return simpleItemsColumn;
    }

    let finalColumns = columns.filter((cols) => cols.visible);

    if (!props.myItemsColumns) {
      return finalColumns;
    }

    // Find the index of the "MESSAGES" column
    const actionsColumnIndex = finalColumns.findIndex(
      (col) => col.title === AlectifyText.MESSAGES,
    );

    // Insert myItemsColumns before the "MESSAGES" column
    if (actionsColumnIndex !== -1) {
      finalColumns = [
        ...finalColumns.slice(0, actionsColumnIndex),
        ...myItemsColumns,
        ...finalColumns.slice(actionsColumnIndex),
      ];
    } else {
      finalColumns = [...finalColumns, ...myItemsColumns];
    }

    return finalColumns;
  }, [pmExternal.activeWorkOrderCardSwitcher, props.filter]);
};

export default useTasksColumn;
