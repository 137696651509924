import { IReduxActionResponse } from "redux/interfaces";
import {
  IPmAttachment,
  IPmInternal,
  IPmInternalResponse,
  IPmInternalState,
  IPrevMaintenanceState,
} from "../pm-internal.interface";
import { IPmInternalCount } from "services/pm-internal/pm-internal.interface";
import { pmInternalInitialState } from "redux/components/pm-internal/pm-internal.initialState";

export const getPmInternal = (
  state: any,
  action: IReduxActionResponse<IPmInternalResponse>,
) => {
  const { payload } = action;
  return {
    ...state,
    data: payload?.data,
    meta: payload?.meta,
    message: payload?.message,
  };
};

export const setPmInternalLoader = (
  state: any,
  action: IReduxActionResponse<boolean>,
) => {
  const { payload } = action;
  return {
    ...state,
    fetching: payload,
    pmAttachments: {
      ...state.pmAttachments,
      fetching: payload,
    },
  };
};

export const getPmAttachments = (
  state: any,
  action: IReduxActionResponse<IPmAttachment>,
) => {
  const { payload } = action;
  return {
    ...state,
    pmAttachments: {
      ...state.pmAttachments,
      data: payload?.data,

      meta: payload?.meta,
    },
  };
};

export const setAttachmentLoader = (
  state: any,
  action: IReduxActionResponse<boolean>,
) => {
  const { payload } = action;
  return {
    ...state,
    attachmentLoader: payload,
  };
};

export const saveMediaAttachments = (
  state: any,
  action: IReduxActionResponse<IPmAttachment>,
) => {
  const { payload } = action;
  return {
    ...state,
    attachmentsMedia: {
      ...state.pmAttachments,
      data: payload?.data,
      meta: payload?.meta,
    },
  };
};

export const setPmInternalCounts = (
  state: IPmInternalState,
  action: IReduxActionResponse<IPmInternalCount>,
): IPmInternalState => {
  const { payload } = action;
  return {
    ...state,
    pendingCounts: payload?.pending || 0,
    skippedCounts: payload?.skipped || 0,
    completedCounts: payload?.completed || 0,
  };
};

export const setPrevMaintenanceDetail = (
  state: IPrevMaintenanceState,
  action: IReduxActionResponse<IPmInternal>,
): IPrevMaintenanceState => {
  const { payload } = action;
  return {
    ...state,
    pmDetail: {
      ...state.pmDetail,
      data: payload,
    },
  };
};

export const setTodayPmInternal = (
  state: IPmInternalState,
  action: IReduxActionResponse<IPmInternal[]>,
): IPmInternalState => {
  const { payload } = action;
  return {
    ...state,
    todayPmInternal: payload || [],
  };
};

export const resetPmInternalState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return {
    ...pmInternalInitialState,
  };
};
