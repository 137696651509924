import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, Col, Row, Tabs, TabsProps, Typography } from "antd";
import { ROUTES } from "routes/Routes.constants";
import { IRootState } from "redux/rootReducer";
import { isExternalUser } from "utils/helpers";
import DrawSpareParts from "./tabs/draw";
import AlectifyText from "static/texts.json";
import AddSparePart from "./tabs/add";
import RefillSpareParts from "./tabs/refill";
import RefillIcon from "components/icons/RefillIcon";
import DrawColoredIcon from "components/icons/DrawColoredIcon";
// import { INVENTORY_OPTIONS } from "./InventoryManagement.constants";
// import { setShowTeamInventory } from "redux/components/common/sources";
// import GlobalInventory from "./tabs/global-inventory/GlobalInventory";

const InventoryManagement: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeTab =
    new URLSearchParams(location.search).get("page") || "spare-parts";
  // const { showTeamInventory } = useSelector(
  //   (state: IRootState) => state.common,
  // );

  const { user } = useSelector(({ auth }: IRootState) => auth);

  // const globalInventoryItems: TabsProps["items"] | any[] = [
  //   {
  //     key: "company-wide",
  //     label: (
  //       <>
  //         <DrawIcon /> {`${AlectifyText.COMPANY_WIDE}`}
  //       </>
  //     ),
  //     children: <GlobalInventory />,
  //     visible: true,
  //   },
  // ];

  const items: TabsProps["items"] | any[] = [
    {
      key: "spare-parts",
      label: (
        <>
          <DrawColoredIcon defaultBlack /> {`${AlectifyText.SPARE_PARTS}`}
        </>
      ),
      children: <DrawSpareParts />,
      visible: true,
    },
    {
      key: "orders-management",
      label: (
        <>
          <RefillIcon /> {`${AlectifyText.ORDERS_MANAGEMENT}`}
        </>
      ),
      children: <RefillSpareParts />,
      visible: !isExternalUser(user),
    },
    {
      key: "add-new",
      label: (
        <>
          <PlusOutlined style={{ fontSize: 25, margin: 0 }} />{" "}
          {`${AlectifyText.ADD_NEW_PART_NUMBER}`}
        </>
      ),
      children: <AddSparePart />,
      visible: !isExternalUser(user),
    },
  ];

  // const onButtonChange = ({ target: { value } }: RadioChangeEvent) => {
  //   dispatch(setShowTeamInventory(value));
  // };

  return (
    <div className="pR-10">
      <Breadcrumb
        items={[
          {
            title: "Home",
            href: `#`,
            onClick: () => {
              navigate(
                `${isExternalUser(user) ? ROUTES.MY_ITEMS : ROUTES.PROJECT}`,
              );
            },
          },
          {
            title: AlectifyText.SPARE_PARTS,
          },
        ]}
      />
      <div className="sub-project-tabs">
        <Col span={24}>
          <Row justify={"space-between"} align={"middle"}>
            <Col span={12}>
              <Typography.Title level={4} className="mt-10">
                {AlectifyText.SPARE_PARTS}
              </Typography.Title>
            </Col>
            {/* <Col span={12} className="text-align-right">
              <Space className="alectify-work-order-tabs">
                <Radio.Group
                  options={INVENTORY_OPTIONS}
                  onChange={onButtonChange}
                  value={showTeamInventory}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Space>
            </Col> */}
          </Row>
        </Col>

        <Tabs
          destroyInactiveTabPane
          defaultActiveKey={activeTab}
          items={items.filter((tab: any) => tab.visible)}
          onChange={(tabId) => navigate({ search: `page=${tabId}` })}
        />
      </div>
    </div>
  );
};

export default InventoryManagement;
