import { AxiosResponse } from "axios";
import { http } from "utils/client";
import {
  CREATE_TEAMS,
  DELETE_TEAMS,
  GET_MASTER_PROJECT_TEAMS_LIST,
  UPDATE_TEAMS,
} from "./teams.endpoints";
import { ITeamPayload, ITeamsResponse } from "./teams.interface";
import { IPagination } from "components/shared/table/AlectifyTable.interface";

/**
 * Fetches Master project Teams list.
 *
 * @param projectId - The ID of the master project to fetch project users for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project users data.
 * @throws If an error occurs during the request.
 */
export const fetchTeamsList = async (
  projectId: string,
  params?: IPagination, // Make params optional
): Promise<ITeamsResponse> => {
  try {
    const url = GET_MASTER_PROJECT_TEAMS_LIST.replace(
      "{master_project_id}",
      projectId,
    );
    const response: AxiosResponse<ITeamsResponse> = await http.get(url, params);
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Deletes teams for the master project.
 *
 * @param projectId - The ID of the master project to fetch project users for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project users data.
 * @throws If an error occurs during the request.
 */
export const deleteTeam = async (
  projectId: string,
  teamId: string,
): Promise<ITeamsResponse> => {
  try {
    const url = DELETE_TEAMS.replace("{master_project_id}", projectId).replace(
      "{team_id}",
      teamId,
    );
    const response: AxiosResponse<ITeamsResponse> = await http.delete(url);
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Creates new user for the master project.
 *
 * @param projectId - The ID of the master project to fetch project users for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project users data.
 * @throws If an error occurs during the request.
 */
export const createTeams = async (
  projectId: string,
  body: ITeamPayload,
): Promise<ITeamsResponse> => {
  try {
    const url = CREATE_TEAMS.replace("{master_project_id}", projectId);
    const response: AxiosResponse<ITeamsResponse> = await http.post(url, body);
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Creates new user for the master project.
 *
 * @param projectId - The ID of the master project to fetch project users for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project users data.
 * @throws If an error occurs during the request.
 */
export const updateTeams = async (
  projectId: string,
  teamId: string,
  body: ITeamPayload,
): Promise<ITeamsResponse> => {
  try {
    const url = UPDATE_TEAMS.replace("{master_project_id}", projectId).replace(
      "{team_id}",
      teamId,
    );
    const response: AxiosResponse<ITeamsResponse> = await http.patch(url, body);
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};
