import { FormInstance } from "antd";
import { UploadFile } from "antd/lib";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { IAssetPackage } from "redux/components/asset-packages";
import { IAsset } from "redux/components/assets";
import { IUser } from "redux/components/Auth";
import { IMasterProject } from "redux/components/master-project";
import { ISubProject } from "redux/components/sub-project";
import { ITask } from "redux/components/tasks";
import { IProcedure } from "services/procedures/procedures.interface";
import { ITeams } from "services/teams/teams.interface";

/* Interfaces */
export interface IWorkOrderCreateEditProps {
  FormInstance: FormInstance;
  editing: boolean;
  copying?: boolean;
  editType: string;
  isTask: boolean;
  subProjectId: string | undefined;
  masterProjectId: string | undefined;
  taskId: string;
  type: string;
  callbackEffects?: (
    options?: IPagination,
    requestId?: string | null,
    editArg?: any,
  ) => void;
}
export interface IWorkOrderInitialState {
  loading: boolean;
  selectedMasterProject: IMasterProject | null;
  selectedMembertype: string | null;
  projectTeams: ITeams[] | null;
  projectTeamMembers: IUser[] | null;
  selectedTeamMembers: IUser[] | ITeams[] | null;
  selectedTeamId: string | null;
  selectedSubProject: ISubProject | null;
  selectedRowKeys: string[];
  selectedAssets: IAsset[] | null;
  selectedAssetPackage: IAssetPackage[] | null;
  isRecurring: boolean;
  taskDetails: ITask | null;
  selectedProcedureLibrary: IProcedure | null;
  procedureLibraryId: string | null;
  taskCategory: string | null;
  fileList: UploadFile[];
  validationMessage: string | null;
}

export interface IWorkOrderBasicInfoCreationProps {
  selectedMasterProject: IMasterProject | null;
  FormInstance: FormInstance;
  selectedSubProject: ISubProject | null;
  selectedRowKeys: string[];
  selectedAssets: IAsset[] | null;
  selectedAssetPackage: IAssetPackage[] | null;
  taskDetails: ITask | null;
  editing: boolean;
  isTask: boolean;
  type: string;
  copying: boolean;
  equipmentModalHandler: () => void;
  onSelectMasterProject: (value: string, project: IMasterProject) => void;
  onSelectSubProject: (value: string, project: ISubProject) => void;
  onSelectTaskCategory: (value: string) => void;
  handleImageUpload: (fileList: any[]) => void;
}

export interface IWorkOrderScheduleProps {
  FormInstance: FormInstance;
  isRecurring: boolean;
  taskDetails: ITask | null;
  editing: boolean;
  editType: string;
  taskCategory: string;
  isTask: boolean;
  copying: boolean;
  onRecurringChange: (value: boolean) => void;
}
export interface IWOCreateTeamSelection {
  FormInstance: FormInstance;
  masterProjectId: string;
  selectedMembertype: string;
  selectedTeamMembers: IUser[] | ITeams[] | null;
  projectTeamMembers: IUser[] | null;
  projectTeams: ITeams[] | null;
  taskDetails: ITask | null;
  editing: boolean;
  copying?: boolean;
  onSelectTeamMember: (value: string) => void;
  onDeselectTeamMember: (value: string) => void;
  onSelectMemberType: (value: string) => void;
  onSelect?: (item: any) => void;
  onDeselect?: (item: any) => void;
  onSelectType?: (item: any) => void;
  onCreateAssignee: (item: any, type: string) => void;
  onRemoveAssignee: (item: any, type: string) => void;
}

export interface IWorkOrderProcedureSelectionProps {
  isTask: boolean;
  onSelectProcedure: (procedure: IProcedure | null) => void;
  procedure: IProcedure | null;
  projectId: string;
}
/* *************** */

/* Enums */
export enum WorkOrderTypeEnum {
  CORRECTIVE_MAINTENANCE = "CORRECTIVE_MAINTENANCE",
  PREVENTIVE_MAINTENANCE = "PREVENTIVE_MAINTENANCE",
  TASK = "TASK",
  // CLEANUP = "CLEANUP",
  // REPLACEMENT = "REPLACEMENT",
  // OTHERS = "OTHERS",
  DAMAGE = "DAMAGE",
  INSPECTION = "INSPECTION",
  SAFETY = "SAFETY",
  DEFICIENCY = "DEFICIENCY",
}
/* *************** */
