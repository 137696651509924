interface ISuccessTickIconProps {
  height?: number;
  width?: number;
}
const SuccessTickIcon: React.FC<ISuccessTickIconProps> = (
  props: ISuccessTickIconProps,
) => {
  return (
    <svg
      width={props.width || "15"}
      height={props.height || "15"}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="success-icon">
        <path
          id="Ellipse 313"
          d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z"
          fill="white"
        />
        <path
          id="Path 1638"
          d="M7.5 1C6.21442 1 4.95772 1.38122 3.8888 2.09545C2.81988 2.80968 1.98676 3.82484 1.49479 5.01256C1.00282 6.20028 0.874095 7.50721 1.1249 8.76809C1.3757 10.029 1.99477 11.1872 2.90381 12.0962C3.81285 13.0052 4.97104 13.6243 6.23192 13.8751C7.49279 14.1259 8.79973 13.9972 9.98744 13.5052C11.1752 13.0132 12.1903 12.1801 12.9046 11.1112C13.6188 10.0423 14 8.78558 14 7.5C14 6.64641 13.8319 5.80117 13.5052 5.01256C13.1786 4.22394 12.6998 3.50739 12.0962 2.90381C11.4926 2.30023 10.7761 1.82144 9.98744 1.49478C9.19883 1.16813 8.35359 1 7.5 1ZM6.2 10.75L2.95 7.5L3.866 6.584L6.2 8.91L11.134 3.977L12.05 4.9L6.2 10.75Z"
          fill="#3CAC15"
        />
      </g>
    </svg>
  );
};

export default SuccessTickIcon;
