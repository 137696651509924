import AlectifyText from "static/texts.json";
import { ColumnProps } from "antd/es/table";
import { ColumnGroupType } from "antd/lib/table";
import {
  IDrCheckpoint,
  IRound,
} from "services/data-round/data-round.interface";
import CheckIcon from "components/icons/CheckIcon";
import { Tooltip } from "antd";

const useRoundDetails = () => {
  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.CHECKPOINT,
      key: "checkpoint",
      dataIndex: "checkpoint",
      ellipsis: true,
      width: 200,
      render: (value: IDrCheckpoint, record: IRound) => {
        return (
          <Tooltip title={record.checkpoint?.name || "-"}>
            {record.checkpoint?.name || "-"}
          </Tooltip>
        );
      },
    },
    {
      title: AlectifyText.OPERATING_RANGE,
      key: "checkpoint",
      dataIndex: "checkpoint",
      ellipsis: true,
      width: 200,
      render: (value: IDrCheckpoint) => (
        <Tooltip title={value?.description || ""}>
          {value?.description || ""}
        </Tooltip>
      ),
    },
    {
      title: AlectifyText.ISSUES_REPORTED,
      key: "status",
      dataIndex: "status",
      ellipsis: true,
      render: (value: boolean) =>
        !Boolean(value) ? <CheckIcon fill="#ff0000" /> : "",
    },
    {
      title: AlectifyText.CRITICAL_ISSUE,
      key: "critical_issue",
      dataIndex: "critical_issue",
      ellipsis: true,
      render: (value: boolean) =>
        Boolean(value) ? <CheckIcon fill="#ff0000" /> : "",
    },

    {
      title: AlectifyText.KNOWN_ISSUE,
      key: "known_issue",
      dataIndex: "known_issue",
      ellipsis: true,
      // width: 70,
      render: (value: boolean) =>
        Boolean(value) ? <CheckIcon fill="#ff0000" /> : "",
    },

    {
      title: AlectifyText.READINGS,
      key: "reading_key",
      dataIndex: "reading_key",
      ellipsis: true,
      render: (value: any) => (
        <Tooltip title={value || ""}>{value || ""}</Tooltip>
      ),
    },

    {
      title: AlectifyText.COMMENTS,
      key: "latest_comment",
      dataIndex: "latest_comment",
      ellipsis: true,
      width: 250,
      render: (value: string) => (
        <Tooltip title={value || ""}>{value || ""}</Tooltip>
      ),
    },

    /*  {
      title: "Active",
      key: "checkpoint_active_status",
      dataIndex: "checkpoint_active_status",
      ellipsis: true,
      render: (value: boolean) => (Boolean(value) ? "Yes" : "No"),
    }, */

    {
      title: AlectifyText.NUMBER_OF_DOCUMENTS,
      key: "docs_count",
      dataIndex: "docs_count",
      ellipsis: true,
      render: (value: any) => value || 0,
    },
    {
      title: AlectifyText.NUMBER_OF_COMMENTS,
      key: "comments_count",
      dataIndex: "comments_count",
      ellipsis: true,
      render: (value: any) => value || 0,
    },
  ];
  return columns;
};

export default useRoundDetails;
