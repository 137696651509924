import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { getSubProjects } from "redux/components/sub-project/sources";
import AlectifyDrawer from "components/drawer";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootState } from "redux/rootReducer";
import { ISubProject } from "redux/components/sub-project";
import { setActiveSubProject } from "redux/components/common/sources";
import { ROUTES } from "routes/Routes.constants";
import AlectifyInput from "components/shared/input/index";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyText from "static/texts.json";
import AlectifyEmpty from "components/shared/empty/AlectifyEmpty";
import SubProjectCard from "components/shared/custom-cards/sub-project-card/SubProjectCard";
import "./SwtichSubProjectDrawer.scss";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import SearchIcon from "components/icons/SearchIcon";
import { ISwitchSubProjectProps } from "./SwithSubProjectDrawer.interface";

const SwitchSubProjectDrawer: React.FC<ISwitchSubProjectProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subprojects = useSelector((state: IRootState) => state.subProject);
  const [filtered, setFiltered] = useState<ISubProject[]>(
    subprojects?.data || [],
  );

  useEffect(() => {
    setFiltered(subprojects?.data || []);
  }, [subprojects?.data]);

  const onSearchSubProjects = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = event.target.value.toLowerCase();

    if (subprojects.data) {
      const matchingObjects = subprojects.data.filter((item: ISubProject) => {
        // Remove extra white spaces and convert to lowercase for case-insensitive search
        const cleanedValue = item.name.replace(/\s+/g, " ").toLowerCase();
        if (cleanedValue.includes(searchString.toLowerCase())) {
          return true;
        }
        return false;
      });
      setFiltered(matchingObjects);
    }
  };

  useEffect(() => {
    dispatch(getSubProjects(props.masterProjectId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.masterProjectId]);

  const onClickSubProject = (subProject: ISubProject) => {
    dispatch(setActiveSubProject(subProject));
    navigate(
      `${ROUTES.MASTER_PROJECT_DETAILS}/${props.masterProjectId}${ROUTES.SUB_PROJECT}/${subProject?.id}`,
    );
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
    });
  };

  return (
    <>
      <AlectifyInput
        type="text"
        prefix={<SearchIcon />}
        name="search"
        placeholder="Search"
        className="switch-sub-project-searchbar"
        onChange={onSearchSubProjects}
        allowClear
      />
      <div className="alectify-sub-project-list-container">
        <Spin spinning={subprojects?.fetching}>
          {filtered?.map((subProject: ISubProject) => {
            return (
              <SubProjectCard
                key={subProject?.id}
                subProject={subProject}
                onClick={() => onClickSubProject(subProject)}
              />
            );
          })}

          {isEmpty(filtered) && (
            <AlectifyEmpty description={AlectifyText.NO_SUB_PROJECTS_FOUND} />
          )}
        </Spin>
      </div>
    </>
  );
};

SwitchSubProjectDrawer.defaultProps = {
  masterProjectId: "",
  navigate: () => {},
  showEditButtons: false,
};
export default SwitchSubProjectDrawer;
