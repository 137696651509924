import { Col, Form, message, Row, Spin } from "antd";
import { ITaskDenialFormProps } from "./TasksTable.interface";
import AlectifyInput from "../input";
import { IPmCommentCreationPayload } from "services/pm-external/pm-external.interface";
import { ITask } from "redux/components/tasks";
import { IPmExternal, PM_TYPES } from "redux/components/pm-external";
import { createNewPmComment } from "services/pm-external/pm-external.service";
import { PM_STATUS } from "enums";
import { getPmsCounts } from "redux/components/pm-internal/sources";
import { useDispatch, useSelector } from "react-redux";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import { useState } from "react";
import { MESSAGES } from "constants/messages";

const TaskDenialForm: React.FC<ITaskDenialFormProps> = (
  props: ITaskDenialFormProps,
) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: any) => state);
  const [loader, setLoader] = useState<boolean>(false);

  const onDenied = async (values: any, pmExternal?: ITask | IPmExternal) => {
    try {
      if (loader) return;
      setLoader(true);
      const commentPayload: IPmCommentCreationPayload = {
        content_type: "text",
        reference_type: PM_TYPES.PM_EXTERNAL,
        text: `Reason for Denial: ${values.reason}`,
        sent_by: auth.user.id,
      };
      const response = await createNewPmComment(
        pmExternal?.subProject?.id || "",
        pmExternal?.id || "",
        commentPayload,
      );
      const isSuccess = await props.updateStatus(
        pmExternal as any,
        PM_STATUS.DENIED,
        undefined,
        undefined,
        undefined,
        response.data[0].id || "",
      );
      setLoader(false);
      if (isSuccess) {
        ModalServiceInstance.forceCloseAll();
        message.success("The task has been denied.");
        if (!props.isDetailPage) {
          dispatch(getPmsCounts(props.masterProjectId, props.subProjectId));
        }
      }
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  return (
    <Spin spinning={loader}>
      <Row justify={"start"} align={"middle"}>
        <Col span={24}>
          <Form
            form={props.form}
            onFinish={(values) => onDenied(values, props.pmExternal)}
            layout="vertical"
          >
            <AlectifyInput
              name="reason"
              label={"Reason"}
              rows={4}
              style={{
                height: 200,
                width: 200,
              }}
              resizeable
              type="textarea"
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    "Reason",
                  ),
                },
              ]}
            />
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

export default TaskDenialForm;
